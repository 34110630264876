import "./Spinner.scss";

const Spinner = ({ isLoading = false }) => {
  return (
    isLoading && (
      <div className="loading-overlay absolute z-50 h-full w-full flex justify-center items-center pointer-events-none">
        <div className="spinner"></div>
      </div>
    )
  );
};

export const InlineSpinner = ({ isLoading = false }) => {
  return (
    isLoading && (
      <div className="h-4 flex justify-center items-center pointer-events-none">
        <div className="spinner spinner-inline"></div>
      </div>
    )
  );
};

export default Spinner;

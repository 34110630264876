import React, { useEffect, useState } from "react";
import { getPrescriptions } from "api/prescriptions";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { deletePrescription } from "api/prescriptions";

import PrescriptionsTable from "components/PrescriptionsTable/PrescriptionsTable";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

import { useAuth } from "context/AuthContext";
import { useUser } from "context/UserContext";

const PrescriptionsWidget = ({
  caseId,
  readOnly,
  onEdit,
  onDelete,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { authData } = useAuth();
  const { canI } = useUser();
  const [prescriptions, setPrescriptions] = useState([]);
  const [prescription, setPrescription] = useState(null);
  const [refreshPrescriptions, setRefreshPrescriptions] = useState(true);
  const [showDeletePrescriptionModal, setShowDeletePrescriptionModal] =
    useState(false);

  /**
   * prescriptions table - actions
   *
   *  these are the actions allowed depending on the role
   *  the actions allowed depending on the status of each item
   *  are defined in PrescriptionsTable
   */

  const rowActions = [];

  if (canI("delete", "Prescription"))
    rowActions.push({
      name: "delete",
      icon: "ri-delete-bin-line",
      command: handleDelete,
      title: t("Delete prescription"),
    });

  function handleDelete(row) {
    console.log(row);
    setPrescription(row);
    setShowDeletePrescriptionModal(true);
  }

  const handleDeleteConfirmed = async () => {
    setShowDeletePrescriptionModal(false);
    const response = await deletePrescription(
      authData.access_token,
      prescription.id,
    );
    if (response.code === "ERR_BAD_REQUEST") {
      toast.error(t(response.response.data.detail));
      console.log(response);
    } else {
      toast.success(t("Prescription deleted!"));
      setRefreshPrescriptions(true);
    }
  };

  useEffect(() => {
    console.log("PrescriptionsWidget - useEffect - refreshPrescriptions");
    if (!refreshPrescriptions) return;

    const loadPrescriptions = async () => {
      if (!caseId) {
        setPrescriptions([]);
        return;
      }

      try {
        const data = await getPrescriptions({
          token: authData.access_token,
          caseId,
        });
        setPrescriptions(data);
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setRefreshPrescriptions(false);
      }
    };

    loadPrescriptions();
  }, [authData.access_token, caseId, refreshPrescriptions]);

  return (
    <>
      <div className="prescriptions-widget">
        <PrescriptionsTable
          data={prescriptions}
          actions={rowActions}
          showSearch={false}
          readOnly={readOnly}
          newAction={onSubmit}
          newActionLabel={t("Add prescription")}
          onRowClick={(row) => onEdit && onEdit(row.original.id)}
        />
      </div>

      {showDeletePrescriptionModal && (
        <ConfirmationModal
          title={t("Delete prescription?")}
          message={`${t("Are you sure you want to delete the prescription")} ${
            prescription.drug_name
          }?`}
          yesMessage={t("Delete")}
          onClose={() => setShowDeletePrescriptionModal(false)}
          onSubmit={handleDeleteConfirmed}
        />
      )}
    </>
  );
};

export default PrescriptionsWidget;

import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTable, useFilters, useGlobalFilter, useSortBy } from "react-table";

import Button from "components/Form/Button/Button";
import Input from "components/Form/Input/Input";

import { getDate } from "utils/utils";

// import "./Table.scss";

const Actions = ({ row, actions = [] }) => {
  return (
    <div className="actions-wrapper">
      {actions.map(function (action, idx) {
        return (
          <Button
            key={idx}
            className="btn-action"
            stopPropagation={true}
            onClick={() => action.command(row)}
            title={action.title}
          >
            <i className={action.icon}></i>
          </Button>
        );
      })}
    </div>
  );
};

const StoriesTable = ({
  data,
  actions = [],
  className,
  searchLabel = "Search by title or description",
  searchFields = ["title", "description"],
  searchColumns = null,
  searchGlobal = false,
  newAction = "",
  newActionLabel = null,
  initialState,
  showSearch = true,
  onRowClick,
}) => {
  const [filterInput, setFilterInput] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const { t } = useTranslation();

  const classes = ["table"];
  if (className) classes.push(className);

  const searchFilter = useCallback((rows, columns, query) => {
    return rows.filter((row) => {
      return [row.values["title"], row.values["description"]]
        .join(" ")
        .toString()
        .toLowerCase()
        .includes(query);
    });
  }, []);

  const columns = useMemo(
    () =>
      [
        {
          Header: t("ID"),
          accessor: "id",
        },
        {
          Header: t("Title"),
          accessor: "title",
        },
        {
          Header: t("Description"),
          accessor: "description",
        },
        {
          Header: t("Patient ID"),
          accessor: "patient_id",
        },
        {
          Header: t("Difficulty"),
          accessor: "difficulty_id",
        },
        {
          Header: t("Created by"),
          accessor: "creator",
        },
        {
          Header: t("Created on"),
          accessor: "created_at",
        },
        actions.length > 0 && {
          Header: t("Actions"),
          accessor: "accessor",
          cssClass: "text-center min-cell-width pr-5",
          disableSortBy: true,
          Cell: ({ row: { original } }) => (
            <Actions row={original} actions={actions} />
          ),
        },
      ].filter((item) => item !== false),
    [actions, t],
  );

  /**
   * define a customFilter function to include only specific columns
   * if no searchColumns defined, it will do a global search
   * using useCallback to cache the function definition between re-renders
   */
  const customFilter = useCallback(
    (rows, columns, query) => {
      return rows.filter((row) => {
        return searchColumns
          ? searchColumns.some((column) => {
              return row.values[column]
                .toString()
                .toLowerCase()
                .includes(query);
            })
          : Object.values(row.values).some((cell) => {
              return cell?.toString().toLowerCase().includes(query);
            });
      });
    },
    [searchColumns],
  );

  // Update the state when input changes
  const handleFilterChange = (e) => {
    const value = e.target.value;
    setFilterInput(value);
    if (searchColumns) {
      if (searchColumns.length === 1) setFilter(searchColumns[0], value);
      if (searchColumns?.length > 1) setGlobalFilter(value);
    } else setGlobalFilter(value);
  };

  const handleRowClick = (event, row) => {
    if (onRowClick) {
      setSelectedRow(row.id);
      onRowClick(row.original);
    }
  };

  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    setFilter, // The useFilter Hook provides a way to set the filter
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState,
      globalFilter: searchFilter ? searchFilter : customFilter,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
  );

  return (
    <div className="overflow-hidden">
      {(showSearch || (newAction && newActionLabel)) && (
        <div className="search-wrapper flex items-center">
          {showSearch && (
            <Input
              className="w-96"
              label={t(searchLabel)}
              value={filterInput || ""}
              onChange={handleFilterChange}
            />
          )}
          {newAction && newActionLabel && (
            <Button
              className={`btn-primary m-0 ml-auto ${
                newAction ? "" : "disabled"
              }`}
              onClick={newAction}
            >
              {t(newActionLabel)}
            </Button>
          )}
        </div>
      )}

      <div className="table-wrapper mt-2">
        <div className={classes.join(" ")} {...getTableProps()}>
          <div {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <div
                  {...row.getRowProps()}
                  key={row.getRowProps().key}
                  className={`p-2 border rounded my-4 flex flex-col hover:bg-gray-100 cursor-pointer
                    ${row.id === selectedRow ? "" : ""}
                    ${onRowClick ? "selectable" : ""}
                  `}
                  onClick={(e) => handleRowClick(e, row)}
                >
                  <div className="meta ml-auto mb-1 text-sm self-end flex gap-6 text-gray-500">
                    <div title={t("Story ID")}>
                      {t("Story ID")} {row.values.id}
                    </div>
                    <div title={t("Author")}>
                      <i className="ri-account-circle-line"></i>{" "}
                      {row.values.creator}
                    </div>
                    <div title={t("Date created")}>
                      <i className="ri-calendar-line"></i>{" "}
                      {getDate(row.values.created_at)}
                    </div>
                  </div>

                  <h3 className="my-1 text-sm uppercase">{row.values.title}</h3>

                  <div className="story-description text-sm mb-2">
                    <p>{row.values.description}</p>
                  </div>

                  <div className="story-actions ml-auto text-sm">
                    {actions.length > 0 && (
                      <Actions row={row.original} actions={actions} />
                    )}
                  </div>
                </div>
              );
            })}
            {
              // If there are no rows, show a message
              rows.length === 0 && (
                <div className="p-2 my-8 text-center whitespace-pre-wrap leading-normal text-gray-500">
                  {t(
                    "No stories selected yet.\nAdd stories from the existing ones (right column) or define new ones.",
                  )}
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoriesTable;

import { useEffect, useState, useRef } from "react";

const HelpTooltip = ({ className, text, ...props }) => {
	const buttonRef = useRef(null);
	const tooltipRef = useRef(null);
	const [isHovered, setIsHovered] = useState(false);

	const classes = ["help-wrapper relative z-10"];
	if (className) classes.push(className);

	useEffect(() => {
		if (isHovered && tooltipRef.current && buttonRef.current) {
			const buttonRect = buttonRef.current.getBoundingClientRect();
			const tooltipRect = tooltipRef.current.getBoundingClientRect();
			const viewportWidth = window.innerWidth;

			let left = 0;

			if (buttonRect.left + tooltipRect.width > viewportWidth) {
				left = -tooltipRect.width - 10;
			} else {
				left = buttonRect.width + 10;
			}
			tooltipRef.current.style.left = `${left}px`;
		}
	}, [isHovered]);

	return (
		<div className={classes.join(" ")} {...props}>
			<i
				className="ri-question-line text-xl cursor-help peer"
				ref={buttonRef}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			></i>
			<div
				className={`message__note w-[600px] absolute z-10 top-[10px] shadow-md pointer-events-none opacity-0 peer-hover:opacity-100 `}
				ref={tooltipRef}
			>
				<i className="ri-book-open-line"></i>
				<div>
					{text.split("\n").map((line, index) => (
						<p key={index}>{line}</p>
					))}
				</div>
			</div>
		</div>
	);
};

export default HelpTooltip;

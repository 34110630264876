import { useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal/Modal";
import Input from "components/Form/Input/Input";
import Button from "components/Form/Button/Button";
import Toggle from "components/Form/Toggle/Toggle";

const EditClassModal = (props) => {
	const { t } = useTranslation();
	const [description, setDescription] = useState(props?.description || "");
	const [isActive, setIsActive] = useState(props?.isActive || false);
	const [isPublished, setIsPublished] = useState(props?.isPublished || false);

	const handleSubmit = () => {
		props.onSubmit({ description, isActive, isPublished });
		setDescription("");
		setIsActive("");
		setIsPublished("");
		props.onClose();
	};

	return (
		<Modal
			title={`${t("Edit class")} ID: ${props?.classId}`}
			onClose={props.onClose}
			onSubmit={props.onSubmit}
		>
			<form className="flex flex-col gap-4 w-full" onSubmit={handleSubmit}>
				<p className="mt-4">
					{t(
						"You can change the class description, change the active and published status.",
					)}
				</p>
				<Input
					id="class-id"
					className="mt-4"
					label={t("Class description")}
					type="text"
					name="class-id"
					value={description}
					onChange={(e) => setDescription(e.target.value)}
					autoFocus
					required
				/>
				<Toggle
					id="class-is-published"
					className="mt-4"
					label={t("Published")}
					value={isPublished}
					onChange={(e) => setIsPublished(e.target.checked)}
				/>
				<div className="flex center mt-8">
					<Button className="btn" onClick={props?.onClose} type="button">
						{t("Cancel")}
					</Button>
					<Button className="btn-primary">{t("Save")}</Button>
				</div>
			</form>
		</Modal>
	);
};

export default EditClassModal;

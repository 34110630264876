import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import FilterTabs from "components/Tabs/FilterTabs";
import ClassesTable from "components/ClassesTable/ClassesTable";
import NewClassModal from "components/NewClassModal/NewClassModal";
import EditClassModal from "components/EditClassModal/EditClassModal";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

import { createClass, getClasses, updateClass, deleteClass } from "api/classes";

import { useAuth } from "context/AuthContext";
import { useUser } from "context/UserContext";

const Classes = () => {
	// modals - states
	const [showNewClassModal, setShowNewClassModal] = useState(false);
	const [showEditClassModal, setShowEditClassModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	// hooks
	const navigate = useNavigate();
	const { authData } = useAuth();
	const { canI, userData } = useUser();
	const { t } = useTranslation();

	// table - states
	const [tableData, setTableData] = useState([]);
	const [refreshTable, setRefreshTable] = useState(false);
	const [classId, setClassId] = useState();
	const [description, setDescription] = useState();
	const [isActive, setIsActive] = useState();
	const [isPublished, setIsPublished] = useState();

	// table - filters
	const filters = [
		{
			key: "author",
			label: t("My classes"),
			value: `${userData.first_name} ${userData.last_name}`,
		},
		{ key: "is_active", label: t("All classes"), value: 1 },
	];
	const [filter, setFilter] = useState(filters[0]);

	// table - actions
	const rowActions = [];

	if (canI("update", "Class"))
		rowActions.push({
			name: "edit",
			icon: "ri-pencil-line",
			command: handleEdit,
			title: t("Edit class"),
		});

	if (canI("delete", "Class"))
		rowActions.push({
			name: "delete",
			icon: "ri-delete-bin-line",
			command: handleDelete,
			title: t("Delete class"),
		});

	const createNewClass = async (description) => {
		const response = await createClass({
			token: authData.access_token,
			description: description,
			isPublished: 0,
		});
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
		} else {
			setClassId(response.class_id);
			setRefreshTable(true);
			navigate(`/classes/${response.class_id}`);
		}
	};

	// table - action handlers

	function handleView(id, description, is_active, is_published) {
		navigate(`/classes/${id}`);
	}

	function handleEdit(id, description, is_active, is_published) {
		setClassId(id);
		setDescription(description);
		setIsActive(is_active);
		setIsPublished(is_published);
		setShowEditClassModal(true);
	}

	function handleDelete(id) {
		setClassId(id);
		setShowDeleteModal(true);
	}

	const deleteConfirmed = async () => {
		setShowDeleteModal(false);
		const response = await deleteClass(authData.access_token, classId);
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
		} else {
			setClassId(null);
			setRefreshTable(true);
		}
	};

	const applyFilters = (data, filter) => {
		const { key, value } = filter;

		return data.filter((item) => {
			switch (key) {
				case "is_active":
					return item.is_active === value;
				case "author":
					return item.creator === value;
				default:
					return true; // no specific filter matches
			}
		});
	};

	useEffect(() => {
		const getUnreviewedCount = (data) => {
			const me = `${userData.first_name} ${userData.last_name}`;
			const count = data.filter((item) => {
				return item.creator === me && item.reviewed_at === null;
			}).length;
			console.log(count);
		};

		getUnreviewedCount(tableData);
	}, [userData, tableData]);

	useEffect(() => {
		(async () => {
			const tableData = await getClasses(authData.access_token);

			if (tableData.code === "ERR_BAD_REQUEST") {
				toast.error(t(tableData.response.data.detail));
			} else {
				setTableData(tableData);
				setRefreshTable(false);
			}
		})();
	}, [authData.access_token, refreshTable, t]);

	return (
		<>
			<div className="view">
				<div className="header">
					<h1>{t("Classes")}</h1>
					<HelpTooltip
						className="ml-2 text-gray-400"
						text={t(
							"List of all the classes and their statuses. Teachers can define a new class and send the Class-ID to the students. The view is only visible for teachers and admin.",
						)}
					/>
				</div>

				<div className="content">
					<FilterTabs
						className="pt-2 mb-4 border-b"
						filters={filters}
						onChange={(index) => setFilter(filters[index])}
					/>
					<ClassesTable
						data={applyFilters(tableData, filter)}
						actions={rowActions}
						newAction={() => setShowNewClassModal(true)}
						newActionLabel={t("Create new class")}
						onRowClick={(row) => handleView(row.original.id)}
					/>
				</div>
			</div>

			{showNewClassModal && (
				<NewClassModal
					onClose={() => setShowNewClassModal(false)}
					onSubmit={(description) => {
						setDescription(description);
						createNewClass(description);
					}}
				/>
			)}

			{showEditClassModal && (
				<EditClassModal
					classId={classId}
					description={description}
					isActive={isActive}
					isPublished={isPublished}
					onClose={() => setShowEditClassModal(false)}
					onSubmit={(description, isActive, isPublished) => {
						updateClass({
							token: authData.access_token,
							classId: classId,
							description: description,
							isActive: isActive,
							isPublished: isPublished,
						});
						setClassId(null);
						setRefreshTable(true);
					}}
				/>
			)}

			{showDeleteModal && (
				<ConfirmationModal
					title={t("Disable class")}
					message=<span>
						{t("Are you sure you want to disable the following class?")} ID:{" "}
						{classId}
					</span>
					yesMessage={t("Disable")}
					onClose={() => {
						setClassId(null);
						setShowDeleteModal(false);
					}}
					onSubmit={deleteConfirmed}
				/>
			)}
		</>
	);
};

Classes.displayName = "Classes";

export default Classes;

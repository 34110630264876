import axiosInstance from "api/axiosInstance";

/**
 *  GET /story
 *
 *  get all stories
 */
export const getStories = async (token) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		/*
			the endpoint has pagination
		  without any query parameters, trailing slash is needed
		 */
		.get("/story/", { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 * GET /story/{story_id}
 *
 * get a story by id
 */
export const getStory = async (token, storyId) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		.get(`/story/${storyId}`, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  POST /story
 *
 *  create a story
 */
export const createStory = async ({
	token,
	title,
	description,
	isActive,
	isClassStudy,
	isSelfStudy,
	isShared,
	patientId,
	categoryId,
	difficultyId,
	courseId,
	sourceId,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	let data = {
		title: title,
		description: description,
		is_active: isActive,
		is_class: isClassStudy,
		is_self: isSelfStudy,
		is_shared: isShared,
		patient_id: patientId,
		category_id: categoryId,
		difficulty_id: difficultyId,
		course_id: courseId,
		source_id: sourceId,
	};

	return await axiosInstance
		.post("/story", data, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  PUT /story/{story_id}
 *
 *  update a story
 */
export const updateStory = async ({
	token,
	storyId,
	title,
	description,
	isActive,
	isClassStudy,
	isSelfStudy,
	isShared,
	patientId,
	categoryId,
	difficultyId,
	courseId,
	sourceId,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	let data = {
		title: title,
		description: description,
		is_active: isActive,
		is_class: isClassStudy,
		is_self: isSelfStudy,
		is_shared: isShared,
		patient_id: patientId,
		category_id: categoryId,
		difficulty_id: difficultyId,
		course_id: courseId,
		source_id: sourceId,
	};

	return await axiosInstance
		.put(`/story/${storyId}`, data, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  DELETE /story/{story_id}
 *
 *  delete a story
 */
export const deleteStory = async (token, storyId) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		.delete(`/story/${storyId}`, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

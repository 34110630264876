import axiosInstance from "api/axiosInstance";

/**
 *  GET /class
 *
 *  get all classes
 */
export const getClasses = async (token) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		.get("/class", { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  POST /class
 *
 *  create a class
 */
export const createClass = async ({
	token,
	description,
	isActive,
	isPublished,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	let data = {
		description: description,
		is_active: isActive,
		is_published: isPublished,
	};

	return await axiosInstance
		.post("/class", data, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /class/{class_id}
 *
 *  get a class
 */
export const getClass = async (token, classId) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	return await axiosInstance
		.get(`/class/${classId}`, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  PUT /class/{class_id}
 *
 *  update a class
 */
export const updateClass = async ({
	token,
	classId,
	description,
	isActive,
	isPublished,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	let data = {
		description: description,
		is_active: isActive,
		is_published: isPublished,
	};

	return await axiosInstance
		.put(`/class/${classId}`, data, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  DELETE /class/{class_id}
 *
 *  delete a class
 */
export const deleteClass = async (token, classId) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	return await axiosInstance
		.delete(`/class/${classId}`, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useAuth } from "context/AuthContext";

import {
  getDifficulties,
  createDifficulty,
  updateDifficulty,
  deleteDifficulty,
} from "api/difficulty";

import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import Input from "components/Form/Input/Input";
import Password from "components/Form/Input/Password";
import DateInput from "components/Form/DateInput/DateInput";
import Select from "components/Form/Select/Select";
import Combo from "components/Form/Combo/Combo";
import Textarea from "components/Form/Textarea/Textarea";
import Button from "components/Form/Button/Button";
import Table, { Actions } from "components/Table/Table";

import DifficultyModal from "components/DifficultyModal/DifficultyModal";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

export default function Settings() {
  const { authData } = useAuth();
  const { t } = useTranslation();

  const [difficulties, setDifficulties] = useState([]);
  const [difficulty, setDifficulty] = useState({});
  const [refreshTable, setRefreshTable] = useState(true);
  const [showDifficultyModal, setShowDifficultyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const difficultyActions = useMemo(
    () => [
      {
        name: "delete",
        icon: "ri-delete-bin-line",
        command: (row) => {
          setDifficulty(row);
          setShowDeleteModal(true);
        },
        title: t("Edit difficulty level"),
      },
    ],
    [t],
  );

  const difficultyColumns = useMemo(
    () => [
      {
        Header: t("ID"),
        accessor: "id",
      },
      {
        Header: t("Title"),
        accessor: "name",
      },
      {
        Header: t("Description"),
        accessor: "description",
      },
      {
        Header: t("Actions"),
        accessor: "accessor",
        disableSortBy: true,
        cssClass: "text-center min-cell-width pr-5",
        Cell: ({ row: { original } }) =>
          difficultyActions && (
            <Actions row={original} actions={difficultyActions} />
          ),
      },
    ],
    [difficultyActions, t],
  );

  const selectOptions = [
    { value: 1, label: "Option 1" },
    { value: 2, label: "Option 2" },
  ];

  const comboOptions = [
    { value: 1, label: "A 1" },
    { value: 2, label: "Option 2" },
  ];

  const [input, setInput] = useState("");
  const [password, setPassword] = useState("");
  const [date, setDate] = useState("");
  const [combo, setCombo] = useState(null);
  const [select, setSelect] = useState("");
  const [textarea, setTextarea] = useState("");

  const handleSubmitDifficultyModal = async (data) => {
    try {
      let response = null;

      if (difficulty?.id) {
        response = await updateDifficulty({
          token: authData.access_token,
          difficultyId: difficulty.id,
          name: data.name,
          description: data.description,
        });
      } else {
        response = await createDifficulty({
          token: authData.access_token,
          name: data.name,
          description: data.description,
        });
      }

      if (response.status === 200) {
        setRefreshTable(true);
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleDeleteDifficuly = async () => {
    try {
      const response = await deleteDifficulty({
        token: authData.access_token,
        difficultyId: difficulty.id,
      });

      if (response.status === 200) {
        toast.success(t("Difficulty level deleted!"));
        setRefreshTable(true);
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.detail || "An error occurred";
      toast.error(t(errorMessage));
    } finally {
      setShowDeleteModal(false);
    }
  };

  // get the difficulty levels
  useEffect(() => {
    if (!refreshTable) return;

    const fetchDifficulties = async () => {
      try {
        const response = await getDifficulties(authData.access_token);
        console.log("response", response);
        setDifficulties(response.data);
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setRefreshTable(false);
      }
    };

    fetchDifficulties();
  }, [authData.access_token, refreshTable]);

  return (
    <>
      <div className="my-sessions view">
        <div className="header">
          <h1>{t("Settings")}</h1>
          <HelpTooltip
            className="ml-2 text-gray-400"
            text={t("This view includes administrative settings.")}
          />
        </div>

        <div className="flex flex-col gap-6">
          <div className="w-1/2">
            <h2>Manage the difficulty levels</h2>
            <Table
              columns={difficultyColumns}
              data={difficulties}
              showSearch={false}
              newAction={() => {
                setDifficulty({});
                setShowDifficultyModal(true);
              }}
              onRowClick={(row) => {
                setDifficulty(row.original);
                setShowDifficultyModal(true);
              }}
            />
          </div>
        </div>
        <div className="my-8 ml-6 w-96">
          <h2>Fields & Select elements</h2>
          <Input
            className="my-8"
            label="Input"
            value={input}
            required
            onChange={(e) => setInput(e.target.value)}
          />
          <Input
            className="my-8"
            label="Input"
            value={input}
            adornment="kg/m³"
            required
            onChange={(e) => setInput(e.target.value)}
          />
          <Password
            className="my-8"
            type="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <DateInput
            className="my-8"
            label="Date"
            value={date}
            // pattern="[0-9]{2}-[0-9]{2}-(19|20)\d{2}"
            // maxLength="10"
            // minLength="10"
            placeholder={t("DD-MM-YYYY")}
            required
            onChange={(e) => setDate(e.target.value)}
          />
          <Combo
            className="my-8"
            label="Combo"
            options={comboOptions}
            value={combo}
            onChange={setCombo}
          />
          <Select
            className="my-8"
            label="Select"
            options={selectOptions}
            value={select}
            onChange={setSelect}
          />
          <Textarea
            className="my-8"
            label="Textarea"
            value={textarea}
            onChange={(e) => setTextarea(e.target.value)}
          />
        </div>
        <div className="my-8 ml-6">
          <Button className="btn-primary" onClick={(e) => alert("clicked!")}>
            Primary
          </Button>
          <Button onClick={(e) => alert("clicked!")}>Standard</Button>
          <Button className="disabled" onClick={(e) => alert("clicked!")}>
            Disabled
          </Button>
        </div>
        <div className="mt-8">
          <h3>Output</h3>
          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Input</strong>
                </td>
                <td>{input}</td>
              </tr>
              <tr>
                <td>
                  <strong>Password</strong>
                </td>
                <td>{password}</td>
              </tr>
              <tr>
                <td>
                  <strong>Date</strong>
                </td>
                <td>{date}</td>
              </tr>
              <tr>
                <td>
                  <strong>Combo</strong>
                </td>
                {combo && (
                  <td>
                    [{combo.value}] "{combo.label}"
                  </td>
                )}
              </tr>
              <tr>
                <td>
                  <strong>Select</strong>
                </td>
                <td>{select.label}</td>
              </tr>
              <tr>
                <td>
                  <strong>Textarea</strong>
                </td>
                <td>{textarea}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {showDifficultyModal && (
        <DifficultyModal
          name={difficulty.name}
          description={difficulty.description}
          onClose={() => setShowDifficultyModal(false)}
          onSubmit={(data) => {
            handleSubmitDifficultyModal(data);
            setShowDifficultyModal(false);
          }}
        />
      )}

      {showDeleteModal && (
        <ConfirmationModal
          title={t("Delete difficulty level")}
          message={t("Are you sure you want to delete this difficulty level?")}
          yesMessage={t("Delete")}
          onClose={() => setShowDeleteModal(false)}
          onSubmit={handleDeleteDifficuly}
        />
      )}
    </>
  );
}

Settings.displayName = "Settings";

import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import HelpTooltip from "components/HelpTooltip/HelpTooltip";

import { useAuth } from "context/AuthContext";
import { useUser } from "context/UserContext";

import { getStories, getStory } from "api/stories";

import "./Dashboard.scss";

const Dashboard = () => {
	const { t /*, i18n*/ } = useTranslation();
	const { authData } = useAuth();
	const { canI } = useUser();
	const [story, setStory] = useState(null);

	useEffect(() => {
		const loadStory = async () => {
			try {
				const response = await getStory(authData.access_token, 4);
				console.log(response);
				setStory(response);
			} catch (error) {
				console.error("An error occurred:", error);
			}
		};

		loadStory();

		const loadStories = async () => {
			try {
				const response = await getStories(authData.access_token);
				console.log(response);
			} catch (error) {
				console.error("An error occurred:", error);
			}
		};

		loadStories();
	}, [authData.access_token]);

	return (
		<>
			<div className="dashboard view">
				<div className="header">
					<h1>{t("Dashboard")}</h1>
					<HelpTooltip
						className="ml-2 text-gray-400"
						text={t(
							"This is a placeholder for the Dashboard where in future releases stats and quick actions could be displayed. The content will also be adjusted accordingly to the user's persmissions.",
						)}
					/>
				</div>
				<div className="content">
					<div className="story p-4 bg-slate-100">
						<p className="max-w-3xl">{story?.description || "Loading.."}</p>
					</div>
					<h2>{t("Quick links")}</h2>
					<div className="flex gap-4 my-5">
						{canI("view", "Classes") && (
							<NavLink
								to="/classes"
								className="quicklink flex items-center p-6 gap-4"
							>
								<i className="ri-school-line"></i>
								<span>{t("Manage classes")}</span>
							</NavLink>
						)}
						{canI("view", "Patients") && (
							<NavLink
								to="/patients"
								className="quicklink flex items-center p-6 gap-4"
							>
								<i className="ri-folder-user-line"></i>
								<span>{t("Manage patients")}</span>
							</NavLink>
						)}
						{canI("view", "Users") && (
							<NavLink
								to="/users"
								className="quicklink flex items-center p-6 gap-4"
							>
								<i className="ri-folder-user-line"></i>
								<span>{t("Manage users")}</span>
							</NavLink>
						)}
						{canI("view", "Sessions") && (
							<NavLink
								to="/sessions"
								className="quicklink flex items-center p-6 gap-4"
							>
								<i className="ri-folder-user-line"></i>
								<span>
									{canI("create", "SingleSession")
										? t("My sessions")
										: t("Sessions")}
								</span>
							</NavLink>
						)}
					</div>
					<p className="mt-auto text-sm">
						&copy; {new Date().getFullYear()} <strong>edups</strong> -
						Educational prescribing system. {t("For support send an e-mail to")}{" "}
						<a href="mailto:">support@edups.nl</a>
					</p>
				</div>
			</div>
		</>
	);
};

Dashboard.displayName = "Dashboard";

export default Dashboard;

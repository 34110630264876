import axiosInstance from "api/axiosInstance";

/**
 *  GET /difficulty
 *
 *  get all difficulty levels
 */
export const getDifficulties = async (token) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		/*
			the endpoint has pagination
		  without any query parameters, trailing slash is needed
		 */
		.get("/difficulty/", { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /difficulty/{difficulty_id}
 *
 *  get a difficulty level by id
 */
export const getDifficulty = async ({ token, difficultyId }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		.get(`/difficulty/${difficultyId}`, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  POST /difficulty
 *
 *  create a new difficulty level
 */
export const createDifficulty = async ({ token, name, description }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		name,
		description,
	};

	try {
		const response = await axiosInstance.post("/difficulty", data, { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  PUT /difficulty/{difficulty_id}
 *
 *  update a difficulty level
 */
export const updateDifficulty = async ({
	token,
	difficultyId,
	name,
	description,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		name,
		description,
	};

	console.log(difficultyId, data);

	try {
		const response = await axiosInstance.put(
			`/difficulty/${difficultyId}`,
			data,
			{ headers },
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  DELETE /difficulty/{difficulty_id}
 *
 *  delete a difficulty level
 */
export const deleteDifficulty = async ({ token, difficultyId }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		.delete(`/difficulty/${difficultyId}`, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Badge from "components/Badge/Badge";
import Button from "components/Form/Button/Button";
import CaseTabs from "components/Tabs/CaseTabs";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import ReviewForm from "components/ReviewForm/ReviewForm";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import NewHomeSessionModal from "components/NewHomeSessionModal/NewHomeSessionModal";
import SelectPatientModal from "components/SelectPatientModal/SelectPatientModal";
import PrescriptionModal from "components/PrescriptionModal/PrescriptionModal";

import { getSession, updateSession, deleteSession } from "api/sessions";
import { getCases, createCase, updateCase, deleteCase } from "api/cases";
import { createPrescription } from "api/prescriptions";
import { reviewSession, submitReviewedSession } from "api/reviews";

import { useAuth } from "context/AuthContext";
import { useUser } from "context/UserContext";

import { getDate, isStudent, isTeacher } from "utils/utils";
import { getHelpText } from "utils/helpText";

const SingleSession = ({ readOnly = false }) => {
	const { sessionId } = useParams();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { authData } = useAuth();
	const { userData, canI, amI } = useUser();

	const [session, setSession] = useState([]);
	const [refreshSession, setRefreshSession] = useState(true);

	const [cases, setCases] = useState([]);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [caseId, setCaseId] = useState();
	const [patientId, setPatientId] = useState();
	const [prescriptionId, setPrescriptionId] = useState();
	const [refreshCases, setRefreshCases] = useState(true);

	const [showEditTitleModal, setShowEditTitleModal] = useState(false);
	const [showDeleteSessionModal, setShowDeleteSessionModal] = useState(false);
	const [showSubmitSessionModal, setShowSubmitSessionModal] = useState(false);
	const [showSelectPatientModal, setShowSelectPatientModal] = useState(false);
	const [showPrescriptionModal, setShowPrescriptionModal] = useState(false);
	const [showReviewBox, setShowReviewBox] = useState(false);
	const [showDeleteCaseModal, setShowDeleteCaseModal] = useState(false);

	/**
	 * SESSION HANDLERS
	 */

	const handleUpdateTitle = async (description) => {
		try {
			const response = await updateSession({
				token: authData.access_token,
				sessionId: sessionId,
				description: description,
				classId: 0,
				statusId: 1,
			});
			if (response.status === 200 || response.status === 201) {
				setSession({ ...session, description: description });
				toast.success(t("Session name updated!"));
			} else {
				console.error("Unexpected response:", response);
			}
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setShowEditTitleModal(false);
		}
	};

	const handleDeleteSession = async () => {
		try {
			const response = await deleteSession(authData.access_token, sessionId);
			if (response.status === 200 || response.status === 201) {
				toast.success(t("Session deleted!"));
				navigate("/sessions");
			} else {
				console.error("Unexpected response:", response);
			}
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setShowDeleteSessionModal(false);
		}
	};

	const handleSubmitSession = async () => {
		try {
			const response = await updateSession({
				token: authData.access_token,
				sessionId: session.id,
				description: session.description,
				classId: session.class_id,
				statusId: 2,
			});

			if (response.status === 200 || response.status === 201) {
				// setSession({ ...session, status: "Submitted" });
				setRefreshSession(true);
				toast.success(t("Session submitted!"));
			} else {
				console.error("Unexpected response:", response);
			}
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setShowSubmitSessionModal(false);
		}
	};

	/**
	 * CASETABS HANDLERS
	 */

	const handleAddTab = () => {
		const draftCase = {
			id: 0,
			patient_name: "New case",
			patient_id: null,
			prescriptions: [],
		};

		setSelectedIndex(cases.length);
		setCaseId(0);
		setCases([...cases, draftCase]);
		console.log("added a tab", cases);
	};

	const handleRemoveTab = () => {
		// remove last case if it is a draft case and select the one before the last
		if (cases[cases.length - 1].id === 0) {
			setSelectedIndex(cases.length - 2);
			setCaseId(cases[cases.length - 2].id);
			setCases(cases.slice(0, -1));
		}
	};

	const handleChangeTab = (index) => {
		setSelectedIndex(index);

		const caseId = cases[index].id;

		if (caseId) {
			setCaseId(caseId);
			setPatientId(cases.find((caseItem) => caseItem.id === caseId).patient_id);
			// setPrescriptions(cases.find((caseItem) => caseItem.id === caseId).prescriptions);
		}
	};

	/**
	 * CASE HANDLERS
	 */

	const handleDeleteCase = async () => {
		try {
			const response = await deleteCase({
				token: authData.access_token,
				caseId: caseId,
			});

			if (response.status === 200 || response.status === 201) {
				setRefreshCases(true);
				setSelectedIndex((prev) => (prev - 1 >= 0 ? prev - 1 : 0));
				toast.success(t("Case deleted!"));
				/*
				if (selectedIndex > 0) {
					setPatient(completeTreatmentPlans[selectedIndex - 1].patient);
					setPrescriptions(
						completeTreatmentPlans[selectedIndex - 1].prescriptions,
					);
					setTreatmentPlanId(completeTreatmentPlans[selectedIndex - 1].id);
					setSelectedIndex((prev) => prev - 1);
				}
				*/
			} else {
				console.error("Unexpected response:", response);
			}
		} catch (error) {
			const errorMessage = error.response?.data?.detail || "An error occurred";
			toast.error(t(errorMessage));
			console.error("An error occurred:", error);
		} finally {
			setShowDeleteCaseModal(false);
		}
	};

	/**
	 * PATIENT HANDLERS
	 */

	const handleSubmitPatient = async (patient) => {
		try {
			/*
			const response = await createCase({
				token: authData.access_token,
				sessionId: sessionId,
				patientId: patient.patient.id,
			});
			*/
			const response = await updateCase({
				token: authData.access_token,
				caseId: caseId,
				patientId: patient.patient.id,
			});

			if (response.status === 200 || response.status === 201) {
				setRefreshCases(true);
				toast.success(t("Patient saved!"));
			} else {
				console.error("Unexpected response:", response);
			}
		} catch (error) {
			const errorMessage = error.response?.data?.detail || "An error occurred";
			toast.error(t(errorMessage));
			console.error("An error occurred:", error);
		}
	};

	/**
	 * PRESCRIPTION HANDLERS
	 */

	const handleSubmitPrescription = async (prescription) => {
		try {
			const response = await createPrescription({
				token: authData.access_token,
				caseId,
				drugId: prescription.drugId,
				ROAId: prescription.ROAId,
				doseUnitId: prescription.doseUnitId,
				doseFreqId: prescription.doseFreqId,
				dose: prescription.dose,
				doseFreqNumber: prescription.doseFreqNumber,
				notes: prescription.notes,
			});

			if (response.status === 200 || response.status === 201) {
				setRefreshCases(true);
				toast.success(t("Prescription saved!"));
			} else {
				console.error("Unexpected response:", response);
			}
		} catch (error) {
			const errorMessage = error.response?.data?.detail || "An error occurred";
			toast.error(t(errorMessage));
			console.error("An error occurred:", error);
		}
	};

	/**
	 * REVIEW HANDLERS
	 */

	const handleSubmitReviewForm = async ({ grade, notes }) => {
		try {
			const response = await reviewSession({
				token: authData.access_token,
				sessionId: sessionId,
				gradeId: grade.value,
				notes: notes,
			});

			if (response?.status === 200 || response.status === 201) {
				setRefreshSession(true);
				toast.success(t("Session review saved!"));
			} else {
				console.error("Unexpected response:", response);
			}
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};

	/*
	const handleSubmitReview = async () => {
		console.log("submitting review...");
		const response = await submitReviewedSession({
			token: authData.access_token,
			sessionId: sessionId,
		});
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
			console.log(response);
		} else if (response.status === 200 || response.status === 201) {
			toast.success(t("Review submitted!"));
			setRefreshSession(true);
			setShowSubmitReviewModal(false);
		}
	};
	*/

	// load the session

	useEffect(() => {
		if (!refreshSession) return;

		const loadSession = async () => {
			try {
				const data = await getSession(authData.access_token, sessionId);
				setSession(data);
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setRefreshSession(false);
			}
		};

		loadSession();
	}, [authData.access_token, sessionId, refreshSession]);

	// load the cases

	useEffect(() => {
		if (!refreshCases) return;

		const loadCases = async () => {
			try {
				const data = await getCases(authData.access_token, sessionId);
				if (data && data.length > 0) {
					setCases(data.reverse());
					setCaseId(data[selectedIndex]?.id);
					setPatientId(data[selectedIndex]?.patient_id);
					// setPrescriptions(data[selectedIndex]?.prescriptions);
				}
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setRefreshCases(false);
			}
		};

		loadCases();
	}, [authData.access_token, sessionId, selectedIndex, refreshCases]);

	return (
		<>
			<div className="single-session h-full flex gap-4 items-stretch">
				<div className="view">
					<div className="local-actions -ml-1 flex items-center text-gray-500">
						<Button
							className="h-8 w-6 p-0 mr-2 btn-action font-light text-2xl text-inherit"
							title={t("Back")}
							onClick={() => navigate(-1)}
						>
							<i className="ri-arrow-left-line"></i>
						</Button>
						{session.status === "Draft" && (
							<Button
								className="inline-action mr-0 inline-flex gap-1 items-center"
								title={t("Edit session name")}
								onClick={() => setShowEditTitleModal(true)}
							>
								<i className="ri-pencil-line"></i>
								<span className="text-sm">{t("Edit session name")}</span>
							</Button>
						)}
						{isStudent(userData) && (
							<Button
								className="inline-action mr-0 inline-flex gap-1 items-center"
								onClick={() => alert("Request feedback!")}
							>
								<i className="ri-mail-send-line"></i>
								<span className="text-sm">{t("Request feedback")}</span>
							</Button>
						)}
						{isTeacher(userData) && (
							<Button
								className="inline-action mr-0 inline-flex gap-1 items-center"
								onClick={() => setShowReviewBox(true)}
							>
								<i className="ri-file-check-line"></i>
								<span className="text-sm">{t("Review session")}</span>
							</Button>
						)}
						{isTeacher(userData) && (
							<Button
								className="inline-action mr-0 inline-flex gap-1 items-center"
								onClick={() => alert("Send feedback!")}
							>
								<i className="ri-mail-send-line"></i>
								<span className="text-sm">{t("Send feedback")}</span>
							</Button>
						)}
						{canI("delete", "SingleSession") && session.status === "Draft" && (
							<Button
								className="inline-action danger mr-0 inline-flex gap-1 items-center"
								onClick={() => setShowDeleteSessionModal(true)}
							>
								<i className="ri-delete-bin-line"></i>
								<span className="text-sm">{t("Delete session")}</span>
							</Button>
						)}
						<HelpTooltip
							className="ml-4 text-gray-400 hover:text-gray-600"
							text={t(getHelpText(session.status))}
						/>
					</div>
					<div className="header ml-8 pb-2 border-b">
						<span className="font-light text-xl">[{t("Session")}]</span>
						<h1>{session.description}</h1>
						<Badge className="ms-2 -mt-[2px] text-xxs" value={session.status} />
						<div className="meta ml-auto -mb-[3px] text-sm self-end flex gap-6 text-gray-500">
							<div title={t("Session ID")}>
								{t("Session ID")} {session.id}
							</div>
							{!session.class_id && (
								<div title={t("Session type")}>{t("Self-Study")}</div>
							)}
							{session.class_id && (
								<div title={t("Class ID")}>
									{t("Class ID")} {session.class_id}
								</div>
							)}
							{session.class_id && (
								<div title={t("Class author")}>
									<i className="ri-account-circle-line"></i>{" "}
									{session.class_creator}
								</div>
							)}
							{!session.class_id && amI("Teacher") && (
								<div title={t("Author")}>
									<i className="ri-account-circle-line"></i> {session.creator}
								</div>
							)}
							<div title={t("Session created on")}>
								<i className="ri-calendar-line"></i>{" "}
								{getDate(session.created_at)}
							</div>
						</div>
					</div>

					<div className="content ms-8 mt-2 max-w-screen-xl">
						<h2>{t("Cases in this session")}</h2>
						<CaseTabs
							className="pt-2"
							cases={cases}
							selectedIndex={selectedIndex}
							allowAdd={session.session_type === "Self-Study"}
							onAddTab={handleAddTab}
							onRemoveTab={handleRemoveTab}
							onChange={handleChangeTab}
							onShowDeleteCaseModal={() => setShowDeleteCaseModal(true)}
							onOpenPatientModal={() => setShowSelectPatientModal(true)}
							onOpenPrescriptionModal={(id) => {
								if (id) setPrescriptionId(id);
								setShowPrescriptionModal(true);
							}}
						/>
					</div>
				</div>

				{/*<h3 className="mt-4 mb-5 float-left">{t("Session review")}</h3>*/}
				{showReviewBox && (
					<aside className="aside py-2 px-4 ml-6 flex flex-col rounded rounded-md">
						<div className="flex items-center">
							<h3>{t("Review session")}</h3>
							<Button
								className="btn-close static ml-auto"
								onClick={() => setShowReviewBox(false)}
							>
								<i className="ri-close-line"></i>
							</Button>
						</div>
						<ReviewForm
							className="py-4 border-b border-slate-300"
							target={session}
							readOnly={
								!(
									userData.user_role === "Teacher" &&
									session.status === "Submitted"
								)
							}
							buttonCaption={t("Review session")}
							onClose={() => setRefreshSession(true)}
							onSubmit={handleSubmitReviewForm}
						/>
					</aside>
				)}
			</div>

			{showEditTitleModal && (
				<NewHomeSessionModal
					description={session.description}
					onClose={() => setShowEditTitleModal(false)}
					onSubmit={(description) => handleUpdateTitle(description)}
				/>
			)}

			{showDeleteSessionModal && (
				<ConfirmationModal
					title={t("Delete session?")}
					message={`${t(
						"Are you sure you want to delete the session with the ID",
					)} ${sessionId}? ${t("This operation cannot be undone.")}`}
					yesMessage={t("Delete")}
					onClose={() => setShowDeleteSessionModal(false)}
					onSubmit={handleDeleteSession}
				/>
			)}

			{showSubmitSessionModal && (
				<ConfirmationModal
					title={t("Confirm submit")}
					message={t(
						"You are going to submit the session. After that changes are not possible anymore. Are you sure you want to submit the session?",
					)}
					yesMessage={t("Submit")}
					onClose={() => setShowSubmitSessionModal(false)}
					onSubmit={handleSubmitSession}
				/>
			)}

			{showDeleteCaseModal && (
				<ConfirmationModal
					title={t("Delete treatment plan?")}
					message={`${t(
						"Are you sure you want to delete the treatment plan with the ID",
					)} ${caseId}?`}
					yesMessage={t("Delete")}
					onClose={() => setShowDeleteCaseModal(false)}
					onSubmit={handleDeleteCase}
				/>
			)}

			{showSelectPatientModal && (
				<SelectPatientModal
					onClose={() => setShowSelectPatientModal(false)}
					onSubmit={handleSubmitPatient}
				/>
			)}

			{showPrescriptionModal && (
				<PrescriptionModal
					sessionId={sessionId}
					caseId={caseId}
					patientId={patientId}
					prescriptionId={prescriptionId}
					onClose={() => setShowPrescriptionModal(false)}
					onSubmit={handleSubmitPrescription}
				/>
			)}
		</>
	);
};

SingleSession.displayName = "SingleSession";

export default SingleSession;

import { useTranslation } from "react-i18next";

import "./Badge.scss";

const StatusBadge = ({ value, className, ...props }) => {
  const { t } = useTranslation();
  let classes = ["badge"];

  classes.push(`badge-value__${String(value).toLowerCase().replace(" ", "-")}`);
  if (className) classes.push(className);

  return (
    <span className={classes.join(" ")} {...props}>
      {t(value)}
    </span>
  );
};

export default StatusBadge;

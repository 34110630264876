import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToastContainer, cssTransition } from "react-toastify";

import "./LoginLayout.scss";

const LoginLayout = () => {
  const { t } = useTranslation();
  const toastySlide = cssTransition({
    enter: "slide-top",
    exit: "slide-top-hide",
  });

  return (
    <div className="h-screen flex flex-col">
      <ToastContainer
        position="top-center"
        autoClose={2000}
        transition={toastySlide}
      />
      <main className="main-content login">
        <div className="flex flex-col center">
          <h1 className="app-title mb-5">edups</h1>
          <p className="app-subtitle mb-5">Educational prescribing system</p>
          <Outlet />
        </div>
      </main>
      <footer className="login-footer mt-auto mb-4 text-sm text-center">
        <p>
          &copy; {new Date().getFullYear()} <strong>edups</strong> - Educational
          prescribing system. {t("For support send an e-mail to")}{" "}
          <a href="mailto:">support@edups.nl</a>
        </p>
      </footer>
    </div>
  );
};

export default LoginLayout;

import axiosInstance from "api/axiosInstance";

/**
 *  GET /treatment/user/{session_id}
 *
 *  get all case (treatment plans) from a session
 */
export const getCases = async (token, sessionId) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		.get(`/treatment/user/${sessionId}`, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  POST /treatment/{session_id}
 *
 *  create a case (treatment plan)
 */
export const createCase = async ({ token, sessionId, patientId }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	let data = {
		patient_id: patientId,
	};

	return await axiosInstance
		.post(`/treatment/${sessionId}`, data, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /treatment/{treatment_plan_id}
 *
 *  get a case (treatment plan) by id
 */
export const getCase = async (token, treatmentPlanId) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		.get(`/treatment/${treatmentPlanId}`, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  PUT /treatment/{treatment_plan_id}
 *
 *  update a case (treatment plan)
 */
export const updateCase = async ({ token, caseId, patientId }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	let data = {
		patient_id: patientId,
	};

	return await axiosInstance
		.put(`/treatment/${caseId}`, data, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  DELETE /treatment/{treatment_plan_id}
 *
 *  delete a case (treatment plan)
 */
export const deleteCase = async ({ token, caseId }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	return await axiosInstance
		.delete(`/treatment/${caseId}`, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

import { useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal/Modal";
import Input from "components/Form/Input/Input";
import Button from "components/Form/Button/Button";

// import { AuthContext } from "./../../context/AuthContext";

const NewClassSessionModal = (props) => {
	const { t } = useTranslation();
	const [classId, setClassId] = useState("");

	const handleSubmit = () => {
		props.onSubmit(classId);
		setClassId("");
		props.onClose();
	};

	return (
		<Modal
			title={t("New class session")}
			isOpen={props.isOpen}
			onClose={props.onClose}
			onSubmit={props.onSubmit}
		>
			<form className="flex flex-col gap-4 w-full" onSubmit={handleSubmit}>
				<p className="mt-4">
					{t("Enter the Class ID you received from your teacher")}
				</p>
				<Input
					id="class-id"
					className="mt-4"
					label={t("Class ID")}
					type="number"
					pattern="[0-9]*"
					name="class-id"
					value={classId}
					onChange={(e) => setClassId(e.target.value)}
					autoFocus
					required
				/>
				<div className="flex center mt-8">
					<Button className="btn-primary">{t("Create class session")}</Button>
				</div>
			</form>
		</Modal>
	);
};

export default NewClassSessionModal;

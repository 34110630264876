import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { useAuth } from "context/AuthContext";
import { useUser } from "context/UserContext";

import Button from "components/Form/Button/Button";
import Select from "components/Form/Select/Select";
import Textarea from "components/Form/Textarea/Textarea";

import { getGrades } from "api/grades";

import { getDate } from "utils/utils";

import "./ReviewForm.scss";

const ReviewForm = ({
	className,
	target,
	readOnly,
	reviewCaption = "Review",
	submitCaption = "Save",
	cancelCaption = "Cancel",
	onClose,
	onSubmit,
}) => {
	const { t } = useTranslation();
	const { authData } = useAuth();
	const { canI } = useUser();

	const [isEditMode, setIsEditMode] = useState(false);
	const [grade, setGrade] = useState(null);
	const [notes, setNotes] = useState("");
	const [gradeOptions, setGradeOptions] = useState([]);

	const classes = ["review-form"];
	if (className) classes.push(className);

	const formatGradeOptions = (gradeData) => {
		const gradeOptions = [];
		gradeData.forEach((grade) => {
			gradeOptions.push({ value: grade.id, label: t(grade.description) });
		});
		return gradeOptions;
	};

	const handleClose = (e) => {
		e.preventDefault();
		setIsEditMode(!isEditMode);
		if (onClose) onClose();
	};

	const handleReview = () => {
		setIsEditMode(!isEditMode);
	};

	const handleSubmit = () => {
		if (onSubmit) onSubmit({ grade, notes });
		setIsEditMode(!isEditMode);
	};

	// get the grades and populate the dropdown

	useEffect(() => {
		(async () => {
			const gradeData = await getGrades(authData.access_token);
			if (gradeData === "ERR_BAD_REQUEST") {
				toast.error(t(gradeData.response.data.detail));
				console.log(gradeData);
			} else {
				setGradeOptions(gradeData);
			}
		})();
	}, [authData.access_token, t]);

	useEffect(() => {
		setGrade({
			value: target.grade_id,
			label: t(target.grade),
		});
		setNotes(target.reviewer_notes);
	}, [target, t]);

	return (
		<form className={classes.join(" ")}>
			<div className="table text-sm">
				<div className="table-row">
					<strong>{t("Grade")}</strong>
					<div className="has-select p-0 align-middle">
						<Select
							id="unit"
							value={grade}
							noneLabel={t("Not graded")}
							options={formatGradeOptions(gradeOptions)}
							onChange={(value) => setGrade(value)}
							readOnly={!isEditMode}
						/>
					</div>
				</div>
				<div className="table-row">
					<strong className="block">{t("Comments")}</strong>
				</div>
			</div>
			<div className="table text-sm">
				<div className="table-row">
					<div className="p-0">
						<Textarea
							className={`${!isEditMode ? "border-white" : ""} ${
								notes && "mb-4"
							} text-sm`}
							value={notes || ""}
							readOnly={!isEditMode}
							onChange={(e) => setNotes(e.target.value)}
							rows={5}
						></Textarea>
					</div>
				</div>
			</div>
			<div className="table text-sm">
				<div className="table-row">
					<strong>{t("Reviewer")}</strong>
					<div>{target.reviewer || "-"}</div>
				</div>
				<div className="table-row">
					<strong>{t("Date reviewed")}</strong>
					<div>{target.reviewed_at ? getDate(target.reviewed_at) : "-"}</div>
				</div>
			</div>
			{canI("review", "SingleSession") &&
				target?.total_treatment_pending > 0 && (
					<p className="message__note my-4">
						<i className="ri-information-line"></i>
						{target?.total_treatment_pending > 1 &&
							`${target?.total_treatment_pending} ${t(
								"treatment plans still need to be reviewed before reviewing this session.",
							)}`}
						{target?.total_treatment_pending === 1 &&
							t(
								"One treatment plan still needs to be reviewed before reviewing this session.",
							)}
					</p>
				)}
			{canI("review", "SingleSession") &&
				target?.total_prescription_pending > 0 && (
					<p className="message__note my-4">
						{target?.total_prescription_pending > 1 &&
							`${target?.total_prescription_pending} ${t(
								"prescriptions still need to be reviewed before reviewing this treatment plan.",
							)}`}
						{target?.total_prescription_pending === 1 &&
							t(
								"One prescription still needs to be reviewed before reviewing this treatment plan.",
							)}
					</p>
				)}
			{canI("review", "SingleSession") && (
				<div className="flex justify-end mt-4">
					{!isEditMode && (
						<Button
							className={`btn-primary min-w-[50%] p-2 ${
								(readOnly || target?.total_treatment_pending < 0) && "disabled"
							}`}
							onClick={handleReview}
						>
							{reviewCaption}
						</Button>
					)}
					{isEditMode && (
						<>
							<Link
								className={"min-w-[auto] mr-4 self-center font-sm"}
								onClick={handleClose}
								title="{cancelCaption}"
							>
								{t(cancelCaption)}
							</Link>
							<Button
								className={`btn-primary min-w-[50%] p-2 ${
									readOnly && "disabled"
								} ${!grade && "disabled"} `}
								onClick={handleSubmit}
							>
								{t(submitCaption)}
							</Button>
						</>
					)}
				</div>
			)}
		</form>
	);
};

export default ReviewForm;

import React, { createContext, useContext, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

import { updateCurrentUser } from "api/users";

import { useAuth } from "context/AuthContext";

import { permissions } from "./permissions.js";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { authData } = useAuth();
  const [userData, setUserData] = useState(null);

  // Load user data from local storage on mount
  useEffect(() => {
    const savedUserData = JSON.parse(localStorage.getItem("userData"));
    if (savedUserData) {
      setUserData(savedUserData);
    }
  }, []);

  // Save user data to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem("userData", JSON.stringify(userData));
  }, [userData]);

  const updateUserData = async (updatedUserData) => {
    try {
      const response = await updateCurrentUser(
        authData.access_token,
        updatedUserData.first_name,
        updatedUserData.last_name,
      );
      console.log(response);
      setUserData((prevUserData) => ({ ...prevUserData, ...updatedUserData }));
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  const login = (userObject) => {
    setUserData({ userObject });
  };

  const logout2 = () => {
    setUserData(null);
    localStorage.removeItem("userData");
  };

  const canI = (scope, component) => {
    const role = userData?.user_role;
    const rolePermissions = permissions[role];

    if (!rolePermissions) return false;

    const componentPermissions = rolePermissions[scope];

    if (!componentPermissions) return false;

    return componentPermissions.includes(component);
  };

  const amI = (role) => {
    const userRole = userData?.user_role;
    return userRole === role;
  };

  return (
    <UserContext.Provider
      value={{
        userData,
        setUserData,
        updateUserData,
        login,
        logout2,
        canI,
        amI,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const CanIView = ({ element }) => {
  const { canI } = useUser();
  /*
  const { userData } = useUser();
  console.log(
    "canIView: ",
    userData?.user_role,
    element?.type.displayName || element?.type.name,
  );
  */
  /**
   *displayName does not get messed up when building/minifying
   */
  return canI("view", element?.type.displayName || element?.type.name) ? (
    <>{element}</>
  ) : (
    <Navigate to="/" />
  );
};

export const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUser must be used within an UserProvider");
  }
  return context;
};

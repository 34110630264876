import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useAuth } from "context/AuthContext";

import { getPatients, getPatient } from "api/patients";

import Modal from "components/Modal/Modal";
import PatientsTable from "components/PatientsTable/PatientsTable";
import Badge from "components/Badge/Badge";
import Button from "components/Form/Button/Button";
import Input from "components/Form/Input/Input";
import InputNumber from "components/Form/Input/InputNumber";
import Textarea from "components/Form/Textarea/Textarea";
import Toggle from "components/Form/Toggle/Toggle";
import Select from "components/Form/Select/Select";
import { getDate } from "utils/utils";

import "./StoryModal.scss";

const StoryModal = ({ storyData, isDuplicate, onSubmit, ...props }) => {
	const { authData } = useAuth();
	const { t } = useTranslation();

	// table - states
	const [patients, setPatients] = useState([]);
	const [patient, setPatient] = useState(null);
	const historyBadges = [];

	const [step, setStep] = useState(storyData === null ? 1 : 2);

	console.log(storyData);

	if (patient?.patient_history)
		patient["patient_history"].forEach((item, idx) => {
			if (item.description)
				historyBadges.push(
					<Badge key={idx} className="bg-slate-400" value={item.description} />,
				);
		});
	if (historyBadges.length === 0) historyBadges.push("-");

	const [story, setStory] = useState(
		storyData || {
			title: "",
			description: "",
			patient_id: "",
			difficulty_id: null,
			is_active: true,
			is_class: false,
			is_self_study: false,
			is_shared: false,
		},
	);

	const difficultyOptions = [
		{ value: 1, label: t("Easy") },
		{ value: 2, label: t("Medium") },
		{ value: 3, label: t("Hard") },
	];

	const modalTitle = !storyData
		? t("Create new story")
		: isDuplicate
		? t("Duplicate story")
		: t("Edit story");

	const modalMessage = !storyData
		? t(
				"You are going to add a story. After submitting, changes are possible until the story has been used in a case.",
		  )
		: isDuplicate
		? t(
				"You are going to duplicate a story. After making the wished changes you can submit the story.",
		  )
		: t(
				"You are going to edit a story. After submitting, changes are possible until the story has been used in a case.",
		  );

	const validInputs = () => {
		let result = true;

		return result;
	};

	const handleClose = () => {
		setStory(null);
		props.onClose();
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		if (validInputs()) {
			console.log("submitting story", story);
			onSubmit && onSubmit(story);
			setStory(null);
			props.onClose();
		}
	};

	const applyFilters = (data) => {
		// only return active elements
		return data.filter((object) => object.is_active);
	};

	const handleRowClick = async (row) => {
		setPatient(row.original);

		const desc = `${row.original.first_name} ${row.original.last_name}, ${t(
			"born on",
		)} ${getDate(row.original.birth_date)}, ...`;
		setStory({
			...story,
			patientId: row.original.id,
			description: `${desc}\n\n${story.description}`,
		});

		const data = await getPatient(authData.access_token, row.original.id);

		if (data === "ERR_BAD_REQUEST") {
			toast.error(t(data.response.response.data));
			console.log(data);
		} else {
			setPatient(data);
		}
	};

	/* get the patients */

	useEffect(() => {
		(async () => {
			const patientsData = await getPatients(authData.access_token);

			if (patientsData.code === "ERR_BAD_REQUEST") {
				toast.error(t(patientsData.response.data.detail));
			} else {
				setPatients(applyFilters(patientsData));
			}
		})();
	}, [authData.access_token, t]);

	/* get the current patient if any */

	useEffect(() => {
		(async () => {
			const response = await getPatient(
				authData.access_token,
				story.patient_id,
			);

			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			} else {
				setPatient(response);
			}
		})();
	}, [authData.access_token, story.patient_id, t]);

	return (
		<Modal
			title={modalTitle}
			isOpen={props.isOpen}
			onClose={handleClose}
			className="story-modal w-3/5 h-4/5 min-w-[1200px]"
		>
			<div className="h-full max-h-full flex flex-col">
				<p className="message__note my-4">
					<i className="ri-book-open-line"></i>
					{modalMessage}
				</p>
				<form className="flex-1 overflow-auto flex" onSubmit={handleSubmit}>
					{/* Step 1 - select the patient */}
					<div
						className={`select-patient w-full h-full flex
						${step === 1 ? "" : "hidden"}`}
					>
						<div className="w-full flex flex-col gap-4 overflow-scroll">
							<div className="h-full flex gap-5">
								<div className="w-full flex flex-col">
									<h3 className="mt-4">{t("Patient list")}</h3>
									<PatientsTable
										data={patients}
										searchLabel={t("Search by first name and/or last name")}
										initialState={{
											hiddenColumns: ["accessor", "is_active"],
										}}
										onRowClick={handleRowClick}
									/>
								</div>
							</div>
						</div>
					</div>

					{/* Step 2 - edit the story */}
					<div
						className={`edit-case w-full h-full flex ${
							step === 2 ? "" : "hidden"
						}`}
					>
						<div className="w-full flex flex-col gap-y-5 overflow-scroll">
							<Input
								id="title"
								label={t("Title")}
								value={story.title}
								autoFocus={true}
								required
								onChange={(e) => setStory({ ...story, title: e.target.value })}
							/>
							<Textarea
								id="description"
								label={t("Description")}
								value={story.description}
								required
								onChange={(e) =>
									setStory({ ...story, description: e.target.value })
								}
							/>
							<InputNumber
								id="patient_id"
								label={t("Patient ID")}
								value={story.patient_id}
								decimals={0}
								required
								onChange={(value) => setStory({ ...story, patient_id: value })}
							/>
							<Select
								id="difficulty_id"
								label={t("Difficulty")}
								value={{
									value: story.difficulty_id,
									label:
										story.difficulty_id &&
										difficultyOptions[story.difficulty_id - 1].label,
								}}
								options={difficultyOptions}
								onChange={(e) => setStory({ ...story, difficulty_id: e.value })}
							/>
							<Toggle
								id="active"
								label={t("Active")}
								checked={story.is_active}
								onChange={(e) =>
									setStory({ ...story, is_active: e.target.checked })
								}
							/>
							<Toggle
								id="class"
								label={t("Class")}
								checked={story.is_class}
								onChange={(e) =>
									setStory({ ...story, is_class: e.target.checked })
								}
							/>
							<Toggle
								id="self-study"
								label={t("Self study")}
								checked={story.is_self_study}
								onChange={(e) =>
									setStory({ ...story, is_self_study: e.target.checked })
								}
							/>
							<Toggle
								id="shared"
								label={t(
									"Shared ouside organisation (not completly implemented)",
								)}
								checked={story.is_shared}
								onChange={(e) =>
									setStory({ ...story, is_shared: e.target.checked })
								}
							/>
						</div>
					</div>

					{/* aside panel with the selected patient */}
					<div className="aside p-4 ml-6 flex flex-col">
						<h3 className="mb-4">{t("Patient information")}</h3>
						<div className="table text-sm">
							<div className="table-row">
								<strong>{t("Patient Id")}</strong>
								<span>{patient?.patient?.id}</span>
							</div>
							<div className="table-row">
								<strong>{t("First name")}</strong>
								<span>{patient?.patient?.first_name}</span>
							</div>
							<div className="table-row">
								<strong>{t("Last name")}</strong>
								<span>{patient?.patient?.last_name}</span>
							</div>
							<div className="table-row">
								<strong>{t("Date of birth")}</strong>
								<span>
									{patient?.patient?.birth_date &&
										getDate(patient.patient.birth_date)}
								</span>
							</div>
							<div className="table-row">
								<strong>{t("Age")}</strong>
								<span>{patient?.patient?.age}</span>
							</div>
							<div className="table-row">
								<strong>{t("Sex")}</strong>
								<span>{patient?.patient?.gender}</span>
							</div>
							<div className="table-row">
								<strong>{t("Body weight")}</strong>
								<span>
									{`${patient?.patient?.body_weight || "-"} ${t(
										patient?.patient?.body_unit,
									)}`}
								</span>
							</div>
							<div className="table-row">
								<strong>{t("eGFR")}</strong>
								<span>
									{patient?.patient?.kidney_function || "-"}{" "}
									{patient?.patient?.kidney_function && t("ml/min/1.73m²")}
								</span>
							</div>
							<div className="table-row">
								<strong>{t("Medical history")}</strong>
							</div>
						</div>
						<div className="table text-sm">
							<div className="table-row">
								<p>{historyBadges}</p>
							</div>
						</div>
						<div className="h-full flex flex-col justify-center">
							{step === 1 && (
								<Button
									className={`btn-primary mt-auto self-end ${
										!patient && "disabled"
									}`}
									onClick={() => setStep(step + 1)}
								>
									{t("Next")}
								</Button>
							)}
							{step === 2 && (
								<>
									<Button
										className="!mr-0 self-end"
										onClick={() => setStep(step - 1)}
									>
										{t("Choose another patient")}
									</Button>
									<Button
										className={`btn-primary mt-auto self-end ${
											!patient && "disabled"
										}`}
										onClick={() => onSubmit && onSubmit(story)}
									>
										{t("Save")}
									</Button>
								</>
							)}
						</div>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default StoryModal;

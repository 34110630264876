import { useAuth } from "./../../context/AuthContext";

import Button from "./../../components/Form/Button/Button";

const Logout = () => {
	const { setAuthData } = useAuth();
	return (
		<>
			<form
				className="card flex flex-col gap-4 p-5"
				onSubmit={(e) => {
					e.preventDefault();
					setAuthData(null);
				}}
			>
				<p className="mt-4">Are you sure?</p>
				<Button>Log out</Button>
			</form>
		</>
	);
};

export default Logout;

import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToastContainer, cssTransition } from "react-toastify";

import { useUser } from "context/UserContext";

import TopNav from "components/TopNav/TopNav";
import Sidebar from "components/Sidebar/Sidebar";

import "react-toastify/dist/ReactToastify.css";
import "./MainLayout.scss";

const MainLayout = () => {
  const { t } = useTranslation();
  const { canI, amI } = useUser();
  const navLinks = [];
  const toastySlide = cssTransition({
    enter: "slide-top",
    exit: "slide-top-hide",
  });

  if (canI("view", "Dashboard"))
    navLinks.push({
      path: "/dashboard",
      caption: t("Dashboard"),
      icon: "ri-dashboard-2-line",
    });

  if (canI("view", "Classes"))
    navLinks.push({
      path: "/classes",
      caption: t("Classes"),
      icon: "ri-school-line",
    });

  if (canI("view", "Stories"))
    navLinks.push({
      path: "/stories",
      caption: t("Stories"),
      icon: "ri-file-list-3-line",
    });

  if (canI("view", "Patients"))
    navLinks.push({
      path: "/patients",
      caption: t("Patients"),
      icon: "ri-folder-user-line",
    });

  if (canI("view", "Sessions"))
    navLinks.push({
      path: "/sessions",
      caption: amI("Student") ? t("My sessions") : t("Sessions"),
      icon: "ri-folder-2-line",
    });

  if (canI("view", "Users")) {
    navLinks.push({ caption: "_separator_" });
    navLinks.push({
      path: "/users",
      caption: t("Users"),
      icon: "ri-group-line",
    });
  }

  if (canI("view", "Settings"))
    navLinks.push({
      path: "/settings",
      caption: t("Settings"),
      icon: "ri-settings-3-line",
    });

  navLinks.push({
    path: "/profile",
    caption: t("My profile"),
    icon: "ri-account-circle-line",
    className: "mt-auto",
  });

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        transition={toastySlide}
      />
      <TopNav />
      <Sidebar navLinks={navLinks.filter((item) => item !== false)} />
      <main className="main-content logged-in p-6">
        <Outlet />
      </main>
    </>
  );
};

export default MainLayout;

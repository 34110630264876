import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Badge from "components/Badge/Badge";
import Button from "components/Form/Button/Button";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import StoriesTable from "components/StoriesTable/StoriesTable";
import ClassSessionsTable from "components/ClassSessionsTable/ClassSessionsTable";
import EditClassModal from "components/EditClassModal/EditClassModal";
import PublishClassModal from "components/PublishClassModal/PublishClassModal";
import StoryModal from "components/StoryModal/StoryModal";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import InformationModal from "components/InformationModal/InformationModal";

import { getClass, updateClass, deleteClass } from "api/classes";
import { getClassSessions } from "api/sessions";

import { getStories, createStory } from "api/stories";

import { getDate } from "utils/utils";

import { useAuth } from "context/AuthContext";

const SingleClass = ({ readOnly = false }) => {
	const { classId } = useParams();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { authData } = useAuth();

	const [singleClass, setSingleClass] = useState([]);
	const [stories, setStories] = useState([]);
	const [selectedStories, setSelectedStories] = useState([]);
	const [classSessions, setClassSessions] = useState([]);

	const [story, setStory] = useState({});

	const [refreshClass, setRefreshClass] = useState(true);
	const [refreshStories, setRefreshStories] = useState(true);
	const [refreshTable, setRefreshTable] = useState(true);

	// modals - states
	const [showEditClassModal, setShowEditClassModal] = useState(false);
	const [showPublishClassModal, setShowPublishClassModal] = useState(false);
	const [showDeleteClassModal, setShowDeleteClassModal] = useState(false);
	const [showStoryModal, setShowStoryModal] = useState(false);
	const [showSendClassIdModal, setShowSendClassIdModal] = useState(false);

	const [showSubmittedSessions, setShowSubmittedSessions] = useState(false);

	/**
	 * class sessions table - columns & actions
	 *
	 *  these are the actions allowed depending on the role
	 */

	const columns = [
		{
			Header: t("Author"),
			accessor: "creator",
			cssClass: "min-cell-width font-medium",
		},
		{
			Header: t("Date created"),
			accessor: "created_at",
			cssClass: "text-center min-cell-width",
			Cell: ({ cell: { value } }) => getDate(value),
		},
		{
			Header: t("Grade"),
			accessor: "grade",
			cssClass: "text-center text-xs min-cell-width",
			Cell: ({ cell: { value } }) =>
				value ? <Badge className="badge-status" value={value} /> : "-",
		},
	];

	const handleUpdateClass = async ({ description, isActive, isPublished }) => {
		const response = await updateClass({
			token: authData.access_token,
			classId,
			description,
			isActive,
			isPublished,
		});
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
			console.log(response);
		} else if (response.status === 200) {
			setSingleClass({
				...singleClass,
				description,
				is_active: isActive,
				is_published: isPublished,
			});
		}
	};

	const handlePublishClass = async ({ description, isActive, isPublished }) => {
		const response = await updateClass({
			token: authData.access_token,
			classId,
			description,
			isActive,
			isPublished: 1,
		});
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
			console.log(response);
		} else {
			toast.success(t("Class published!"));
			setSingleClass({
				...singleClass,
				description,
				is_active: isActive,
				is_published: 1,
			});
			setShowSendClassIdModal(true);
		}
	};

	async function handleSubmitStory(data) {
		const response = await createStory({
			token: authData.access_token,
			title: data.title,
			description: data.description,
			isActive: data.isActive,
			isClassStudy: data.isClassStudy,
			isSelfStudy: data.isSelfStudy,
			isShared: data.isShared,
			patientId: data.patientId,
			categoryId: data.categoryId,
			difficultyId: data.difficultyId,
			courseId: data.courseId,
			sourceId: data.sourceId,
		});
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
		} else {
			toast.success(t("Story created!"));

			console.log(response);

			// add story to the list of selected stories and remove it from the list of available
			setSelectedStories([...selectedStories, response.data]);
			setStories(stories.filter((story) => story.id !== response.data.id));

			setRefreshStories(true);
			setShowStoryModal(false);
		}
	}

	// table - sessions action handlers

	async function handleView(sessionId) {
		navigate(`/session/${sessionId}`);
	}

	// aside class action handlers

	const handleDeleteClassConfirmed = async () => {
		setShowDeleteClassModal(false);
		const response = await deleteClass(authData.access_token, classId);
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
			console.log(response);
		} else {
			toast.success(t("Class deleted!"));
			navigate("/classes");
		}
	};

	const handleClose = () => {
		navigate("/classes");
	};

	const handleAddStory = (row) => {
		console.log(row);
		setSelectedStories([...selectedStories, row]);
		// remove the story from all stories list
		setStories(stories.filter((story) => story.id !== row.id));
	};

	const handleRemoveStory = (row) => {
		setSelectedStories(selectedStories.filter((story) => story.id !== row.id));
		// add the story back to all stories list
		setStories([...stories, row]);
	};

	// get the class data

	useEffect(() => {
		if (!refreshClass) return;

		console.log("getting the class...", classId);

		const fetchClass = async () => {
			try {
				const response = await getClass(authData.access_token, classId);
				setSingleClass(response);
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setRefreshClass(false);
			}
		};

		fetchClass();
	}, [authData.access_token, classId, refreshClass, t]);

	// get the submitted sessions

	useEffect(() => {
		if (!refreshTable) return;

		console.log("getting the class sessions...", classId);

		const fetchSessions = async () => {
			try {
				const data = await getClassSessions(authData.access_token, classId);
				setClassSessions(data);
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setRefreshTable(false);
			}
		};

		fetchSessions();
	}, [authData.access_token, classId, refreshTable, t]);

	// get the stories

	useEffect(() => {
		if (!refreshStories) return;

		console.log("getting the stories...");

		const fetchStories = async () => {
			try {
				const response = await getStories(authData.access_token);
				setStories(response.data);
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setRefreshStories(false);
			}
		};

		fetchStories();
	}, [authData.access_token, refreshStories]);

	return (
		<>
			<div className="single-class h-full flex gap-4 items-stretch">
				<div className="view">
					<div className="local-actions -ml-1 flex items-center text-gray-500">
						<Button
							className="h-8 w-6 p-0 mr-2 btn-action font-light text-2xl text-inherit"
							title={t("Back")}
							onClick={() => navigate(-1)}
						>
							<i className="ri-arrow-left-line"></i>
						</Button>
						<Button
							className="inline-action mr-0 inline-flex gap-1 items-center"
							onClick={() => setShowEditClassModal(true)}
							title={t("Edit title")}
						>
							<i className="ri-pencil-line"></i>
							<span className="text-sm">{t("Edit title")}</span>
						</Button>
						<Button
							className="inline-action mr-0 inline-flex gap-1 items-center"
							onClick={() => setShowEditClassModal(true)}
							title={t("Save class")}
						>
							<i className="ri-save-line"></i>
							<span className="text-sm">{t("Save class")}</span>
						</Button>
						<Button
							className="inline-action mr-0 inline-flex gap-1 items-center"
							onClick={() => setShowPublishClassModal(true)}
							title={t("Publish class")}
						>
							<i className="ri-eye-line"></i>
							<span className="text-sm">{t("Publish class")}</span>
						</Button>
						<Button
							className="inline-action mr-0 inline-flex gap-1 items-center"
							onClick={handleClose}
						>
							<i className="ri-delete-bin-line"></i>
							<span className="text-sm">{t("Delete class")}</span>
						</Button>
						<Button
							className="inline-action mr-0 inline-flex gap-1 items-center"
							onClick={() => setShowSubmittedSessions(true)}
						>
							<i className="ri-file-copy-line"></i>
							<span className="text-sm">{t("Show submitted sessions")}</span>
						</Button>
						<Button
							className="inline-action mr-0 inline-flex gap-1 items-center"
							onClick={handleClose}
						>
							<i className="ri-file-check-line"></i>
							<span className="text-sm">{t("Review all sessions")}</span>
						</Button>
						<Button
							className="inline-action mr-0 inline-flex gap-1 items-center"
							onClick={handleClose}
						>
							<i className="ri-mail-send-line"></i>
							<span className="text-sm">{t("Send all feedback")}</span>
						</Button>
						<HelpTooltip
							className="ml-4 text-gray-400 hover:text-gray-600"
							text={t(
								"List of all the sessions submitted for this class. You can review them and give a grade.",
							)}
						/>
					</div>
					<div className="header ml-8 pb-2 border-b">
						<span className="font-light text-xl">[{t("Class")}]</span>
						<h1>{singleClass.description}</h1>
						<Badge
							className="ms-2 -mt-[2px] text-xxs"
							value={singleClass.is_published ? "published" : "not published"}
						/>
						<div className="meta ml-auto -mb-[3px] text-sm self-end flex gap-6 text-gray-500">
							<div title={t("Class ID")}>
								{t("Class ID")} {singleClass.id}
							</div>
							<div title={t("Submissions reviewed / Total")}>
								<i className="ri-file-check-line"></i> 0/
								{singleClass.total_sessions}
							</div>
							<div title={t("Author")}>
								<i className="ri-account-circle-line"></i> {singleClass.creator}
							</div>
							<div title={t("Date created")}>
								<i className="ri-calendar-line"></i>{" "}
								{getDate(singleClass.created_at)}
							</div>
						</div>
					</div>
					<div className="content ml-8 mt-2 overflow-hidden">
						<div className="h-full mb-2 flex gap-8">
							<div className="flex flex-col gap-4 flex-1">
								<h2 className="m-0">{t("List of stories for this class")}</h2>
								<StoriesTable
									data={selectedStories}
									columns={columns}
									actions={[]}
									newAction={() => {
										setStory(null);
										setShowStoryModal(true);
									}}
									newActionLabel={t("Create new story")}
									showSearch={false}
									onRowClick={handleRemoveStory}
								/>
							</div>
							<div className="w-1/3 h-full flex flex-col gap-4">
								<h2 className="m-0">{t("List of available stories")}</h2>
								<StoriesTable
									data={stories}
									actions={[]}
									newAction={() => {
										setStory(null);
										setShowStoryModal(true);
									}}
									onRowClick={handleAddStory}
								/>
							</div>

							{showSubmittedSessions && (
								<aside className="aside p-4 -mr-6">
									<div className="mb-4 flex gap-2 items-center">
										<h3> {t("List of sessions in this class")}</h3>
										<Button
											className="btn-close static ml-auto"
											onClick={() => setShowSubmittedSessions(false)}
										>
											<i className="ri-close-line"></i>
										</Button>
									</div>
									<ClassSessionsTable
										data={classSessions}
										columns={columns}
										actions={[]}
										readOnly={readOnly}
										onRowClick={(row) => handleView(row.original.id)}
									/>
									{classSessions.length === 0 && (
										<p className="text-center text-sm mt-32">
											{t(
												"The list is empty. No sessions were submitted for this class yet.",
											)}
										</p>
									)}
								</aside>
							)}
						</div>
					</div>
				</div>
				{/*<aside className="aside p-5 mt-2 -mr-2 -mb-2 ml-6 flex flex-col rounded hidden">
					<h2 className="mt-0 mb-6 mr-2">{t("Les details")}</h2>
					<div className="py-3 flex flex-col gap-4 border-b border-slate-300">
						<div className="table text-sm">
							<div className="table-row">
								<strong>{t("Class Id")}</strong>
								<div>
									<Badge className="badge-outline" value={classId} />
								</div>
							</div>
							<div className="table-row">
								<strong>{t("Author")}</strong>
								<div>{singleClass.creator}</div>
							</div>
							<div className="table-row">
								<strong>{t("Date created")}</strong>
								<div>{getDate(singleClass.created_at)}</div>
							</div>
						</div>
					</div>
					<div className="py-3 flex flex-col gap-4 border-b border-slate-300">
						<div className="table text-sm">
							<div className="table-row">
								<strong>{t("Submitted sessions")}</strong>
								<div>
									{singleClass.total_sessions === 0
										? "-"
										: singleClass.total_sessions}
								</div>
							</div>
							<div className="table-row">
								<strong>{t("Reviewed sessions")}</strong>
								<div>
									{singleClass.total_sessions === 0
										? "-"
										: singleClass.total_sessions}
								</div>
							</div>
						</div>
					</div>
					<div className="py-3 flex flex-col gap-4 border-b border-slate-300">
						<p className="text-sm">{t("1. Review all sessions")}</p>
						<div className="actions mb-1 flex justify-end">
							<Button className="btn-primary mr-0" onClick={handleClose}>
								{t("Batch review")}
							</Button>
						</div>
					</div>
					<div className="py-3 flex flex-col gap-4 border-b border-slate-300">
						<p className="text-sm">
							{t("2. Submit e-mail notification for all reviews")}
						</p>
						<div className="actions mb-1 flex justify-end">
							<Button onClick={handleClose}>{t("Submit review")}</Button>
						</div>
					</div>
					<div className="py-3 flex flex-col gap-4">
						<p className="text-sm">Danger zone</p>
						<div className="actions mb-1 flex justify-end">
							<Button
								className={`btn text-primary ${
									singleClass.status !== "Draft" && "disabled"
								}`}
								title={t("Delete class")}
								onClick={() => setShowDeleteClassModal(true)}
							>
								<i className="ri-delete-bin-line mr-2"></i>
								{t("Delete class")}
							</Button>
						</div>
					</div>
				</aside>*/}
			</div>

			{showEditClassModal && (
				<EditClassModal
					classId={classId}
					description={singleClass.description}
					isActive={singleClass.is_active}
					isPublished={singleClass.is_published}
					onClose={() => setShowEditClassModal(false)}
					onSubmit={handleUpdateClass}
				/>
			)}

			{showPublishClassModal && (
				<PublishClassModal
					classId={classId}
					description={singleClass.description}
					isActive={singleClass.is_active}
					isPublished={singleClass.is_published}
					onClose={() => setShowPublishClassModal(false)}
					onSubmit={handlePublishClass}
				/>
			)}

			{showSendClassIdModal && (
				<InformationModal
					title={t("Class created!")}
					onClose={() => setShowSendClassIdModal(false)}
					onSubmit={() => setShowSendClassIdModal(false)}
				>
					<div className="flex center mb-12">
						<p>
							{t("Class ID")}: <strong>{classId}</strong>
						</p>
					</div>
					<p>
						{t(
							`The class was successfully created! You have to send the students the class ID manually.`,
						)}
					</p>
				</InformationModal>
			)}

			{showDeleteClassModal && (
				<ConfirmationModal
					title={t("Delete class?")}
					message={`${t(
						"Are you sure you want to delete the class with the ID",
					)} ${classId}? ${t("This operation cannot be undone.")}`}
					yesMessage={t("Delete")}
					onClose={() => setShowDeleteClassModal(false)}
					onSubmit={handleDeleteClassConfirmed}
				/>
			)}

			{showStoryModal && (
				<StoryModal
					storyData={story}
					onClose={() => {
						setStory(null);
						setShowStoryModal(false);
					}}
					onSubmit={handleSubmitStory}
				/>
			)}
		</>
	);
};

SingleClass.displayName = "SingleClass";

export default SingleClass;

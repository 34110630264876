import axiosInstance from "api/axiosInstance";

/**
 *  GET /session/user
 *
 *  get all sessions
 */
export const getSessions = async (token) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		.get("/session/user", { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /session/{session_id}
 *
 *  get a session
 */
export const getSession = async (token, sessionId) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		.get(`/session/${sessionId}`, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  PUT /session/{session_id}
 *
 *  update a session
 */
export const updateSession = async ({
	token,
	sessionId,
	description,
	classId,
	statusId,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	let data = {
		description: description,
		class_id: classId,
		status_id: statusId,
	};

	console.log(data);

	return await axiosInstance
		.put(`/session/${sessionId}`, data, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  DELETE /session/{session_id}
 *
 *  delete a session
 */
export const deleteSession = async (token, sessionId) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		.delete(`/session/${sessionId}`, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /session/class/{class_id}
 *
 *  get a all sessions of a class
 */
export const getClassSessions = async (token, classId) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		.get(`/session/class/${classId}`, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  POST /session
 *
 *  create a session
 */
export const createSession = async (
	token,
	description = null,
	class_id = null,
) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	let data = {
		description: description,
		class_id: class_id,
	};

	return await axiosInstance
		.post("/session", data, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

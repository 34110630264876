import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import FilterTabs from "components/Tabs/FilterTabs";
import SessionsTable from "components/SessionsTable/SessionsTable";
import NewSessionModal from "components/NewSessionModal/NewSessionModal";
import NewHomeSessionModal from "components/NewHomeSessionModal/NewHomeSessionModal";
import NewClassSessionModal from "components/NewClassSessionModal/NewClassSessionModal";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

import { createSession, deleteSession, getSessions } from "api/sessions";
import { getClass } from "api/classes";

import { useAuth } from "context/AuthContext";
import { useUser } from "context/UserContext";

const Sessions = ({ readOnly = false }) => {
	// modals - states
	const [showNewSessionModal, setShowNewSessionModal] = useState(false);
	const [showNewHomeSessionModal, setShowNewHomeSessionModal] = useState(false);
	const [showNewClassSessionModal, setShowNewClassSessionModal] =
		useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	// hooks
	const { authData } = useAuth();
	const { canI, amI } = useUser();
	const navigate = useNavigate();
	const { t } = useTranslation();

	// table - states
	const [sessions, setSessions] = useState([]);
	const [refreshTable, setRefreshTable] = useState(false);
	const [sessionId, setSessionId] = useState();

	const helpTooltipText = [
		t(
			"Start a new session and choose the session type. You can edit or delete sessions as long they are not submitted.",
		),
	];

	if (!amI("Student"))
		helpTooltipText.push(t("Teachers can review here the submitted sessions."));

	// table - filters
	const filters = [
		{ key: "is_active", label: t("All sessions"), value: 1 },
		{
			key: "session_type",
			label: t("Class sessions"),
			value: "Class-Study",
		},
		{
			key: "session_type",
			label: t("Home sessions"),
			value: "Self-Study",
		},
	];
	const [filter, setFilter] = useState(filters[0]);

	/**
	 * sessions table - actions
	 *
	 *  these are the actions allowed depending on the role
	 *  the actions allowed depending on the status of each item
	 *  are defined in SessionsTable
	 */

	const rowActions = [];

	if (canI("readAll", "SingleTreatmentPlan"))
		rowActions.push({
			name: "readAll",
			icon: "ri-eye-line",
			command: handleView,
			title: t("View session"),
		});

	if (canI("read", "SingleTreatmentPlan"))
		rowActions.push({
			name: "read",
			icon: "ri-eye-line",
			command: handleView,
			title: t("View session"),
		});

	if (canI("update", "SingleTreatmentPlan"))
		rowActions.push({
			name: "edit",
			icon: "ri-pencil-line",
			command: handleEdit,
			title: t("Edit session"),
		});
	if (canI("delete", "SingleTreatmentPlan"))
		rowActions.push({
			name: "delete",
			icon: "ri-delete-bin-line",
			command: handleDelete,
			title: t("Delete session"),
		});

	// new session router

	const showSessionTypeModal = (sessionType) => {
		switch (sessionType) {
			case "new_home_session":
				setShowNewSessionModal(false);
				setShowNewHomeSessionModal(true);
				break;
			case "new_class_session":
				setShowNewSessionModal(false);
				setShowNewClassSessionModal(true);
				break;
			default:
		}
	};

	const createHomeSession = async (description) => {
		const response = await createSession(
			authData.access_token,
			description,
			null,
		);
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
			console.log(response);
		} else {
			toast.success(t("Home session created!"));
			setRefreshTable(true);
			navigate(`/session/${response.session_id}`);
		}
	};

	const createClassSession = async (classId) => {
		const classObject = await getClass(authData.access_token, classId);
		if (classObject.code === "ERR_BAD_REQUEST") {
			toast.error(t(classObject.response.data.detail));
			console.log(classObject);
		} else {
			const response = await createSession(
				authData.access_token,
				classObject.description,
				classId,
			);
			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
				console.log(response);
			} else {
				toast.success(t("Class session created!"));
				setRefreshTable(true);
				navigate(`/session/${response.session_id}`);
			}
		}
	};

	// table - action handlers

	async function handleView(id) {
		navigate(`/session/${id}`);
	}

	async function handleEdit(id) {
		navigate(`/session/${id}`);
	}

	async function handleDelete(id) {
		setSessionId(id);
		setShowDeleteModal(true);
	}
	/*
	async function handleReview(id) {
		setSessionId(id);
		console.log("review ", id);
	}
	*/

	const applyFilters = (data, filter) => {
		const { key, value } = filter;

		return data.filter((item) => {
			switch (key) {
				case "session_type":
					return item.session_type === value;
				default:
					return true; // no specific filter matches
			}
		});
	};

	// table - confirmation

	const handleDeleteConfirmed = async () => {
		setShowDeleteModal(false);
		const response = await deleteSession(authData.access_token, sessionId);
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
			console.log(response);
		} else {
			toast.success(t("Session deleted!"));
			setRefreshTable(true);
		}
	};

	useEffect(() => {
		(async () => {
			const data = await getSessions(authData.access_token);

			if (data.code === "ERR_BAD_REQUEST") {
				toast.error(t(data.response.data.detail));
				console.log(data);
			} else {
				setSessions(data);
				setRefreshTable(false);
			}
		})();
	}, [authData.access_token, refreshTable, t]);

	return (
		<>
			<div className="view">
				<div className="header">
					<h1>{amI("Student") ? t("My sessions") : t("Sessions")}</h1>
					<HelpTooltip
						className="ml-2 text-gray-400"
						text={helpTooltipText.join(" ")}
					/>
				</div>

				<div className="content">
					<FilterTabs
						className="pt-2 mb-4 border-b"
						filters={filters}
						onChange={(index) => setFilter(filters[index])}
					/>
					<SessionsTable
						data={applyFilters(sessions, filter)}
						actions={rowActions}
						newAction={
							canI("create", "SingleSession")
								? () => setShowNewSessionModal(true)
								: null
						}
						newActionLabel={t("Create new session")}
						readOnly={readOnly}
						onRowClick={(row) => handleView(row.original.id)}
					/>
				</div>
			</div>

			{showNewSessionModal && (
				<NewSessionModal
					onClose={() => setShowNewSessionModal(false)}
					onSubmit={(sessionType) => showSessionTypeModal(sessionType)}
				/>
			)}

			{showNewHomeSessionModal && (
				<NewHomeSessionModal
					onClose={() => setShowNewHomeSessionModal(false)}
					onSubmit={(description) => createHomeSession(description)}
				/>
			)}

			{showNewClassSessionModal && (
				<NewClassSessionModal
					onClose={() => setShowNewClassSessionModal(false)}
					onSubmit={(classId) => createClassSession(classId)}
				/>
			)}

			{showDeleteModal && (
				<ConfirmationModal
					title={t("Delete session?")}
					message={`${t(
						"Are you sure you want to delete the session with the ID",
					)} ${sessionId}? ${t("This operation cannot be undone.")}`}
					yesMessage={t("Delete")}
					onClose={() => setShowDeleteModal(false)}
					onSubmit={handleDeleteConfirmed}
				/>
			)}
		</>
	);
};

Sessions.displayName = "Sessions";

export default Sessions;

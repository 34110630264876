import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import UsersTable from "components/UsersTable/UsersTable";
import EditUserModal from "components/EditUserModal/EditUserModal";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

import { getUsers, updateUser, deleteUser } from "api/users";

import { useAuth } from "context/AuthContext";
import { useUser } from "context/UserContext";

const Users = () => {
	// modals - states
	const [showEditUserModal, setShowEditUserModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	// hooks
	const { authData } = useAuth();
	const { canI } = useUser();
	const { t } = useTranslation();

	// table - states
	const [users, setUsers] = useState([]);
	const [refreshTable, setRefreshTable] = useState(false);
	const [userId, setUserId] = useState();
	const [userEmail, setUserEmail] = useState();
	const [userRoleId, setUserRoleId] = useState();
	const [userIsBlocked, setUserIsBlocked] = useState();
	const rowActions = [];

	// table - action handlers
	const handleEdit = (row) => {
		setUserId(row?.id);
		setUserEmail(row?.email);
		setUserRoleId(row?.user_role_id);
		setUserIsBlocked(row?.is_blocked || false);
		setShowEditUserModal(true);
	};

	function handleDelete({ id, email }) {
		setUserId(id);
		setUserEmail(email);
		setShowDeleteModal(true);
	}

	const handleDeleteConfirmed = async () => {
		setShowDeleteModal(false);
		const response = await deleteUser({
			token: authData.access_token,
			userId: userId,
		});
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
			console.log(response);
		} else {
			toast.success(t("User deleted!"));
			setRefreshTable(true);
		}
	};

	const handleSubmit = async (roleId, isBlocked) => {
		const response = await updateUser(
			authData.access_token,
			userId,
			roleId,
			isBlocked,
		);
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
			console.log(response);
		} else {
			toast.success(t("User updated!"));
			setRefreshTable(true);
		}
	};

	if (canI("update", "User"))
		rowActions.push({
			name: "edit",
			icon: "ri-pencil-line",
			command: handleEdit,
			title: t("Edit user"),
		});

	if (canI("delete", "User"))
		rowActions.push({
			name: "delete",
			icon: "ri-delete-bin-line",
			command: handleDelete,
			title: t("Delete user"),
		});

	const applyFilters = (data) => {
		// return all elements
		return data.filter((object) => true);
	};

	useEffect(() => {
		(async () => {
			const userData = await getUsers(authData.access_token);
			if (userData.code === "ERR_BAD_REQUEST") {
				toast.error(t(userData.response.data.detail));
				console.log(userData);
			} else {
				setUsers(applyFilters(userData));
				setRefreshTable(false);
			}
		})();
	}, [authData.access_token, refreshTable, t]);

	return (
		<>
			<div className="view">
				<div className="header">
					<h1>{t("Users")}</h1>
					<HelpTooltip
						className="ml-2 text-gray-400"
						text={t(`List of all users. Administrators can here block users.`)}
					/>
				</div>

				<div className="content">
					<UsersTable
						data={users}
						actions={rowActions}
						onRowClick={(row) => handleEdit(row.original)}
					/>
				</div>
			</div>

			{showEditUserModal && (
				<EditUserModal
					user={userEmail}
					roleId={userRoleId}
					isBlocked={userIsBlocked}
					onClose={() => setShowEditUserModal(false)}
					onSubmit={handleSubmit}
				/>
			)}

			{showDeleteModal && (
				<ConfirmationModal
					title={t("Delete user?")}
					message={`${t(
						"Are you sure you want to delete the user",
					)} ${userEmail} (${userId})?`}
					yesMessage={t("Delete")}
					onClose={() => setShowDeleteModal(false)}
					onSubmit={handleDeleteConfirmed}
				/>
			)}
		</>
	);
};

Users.displayName = "Users";

export default Users;

import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import StoriesTable from "components/StoriesTable/StoriesTable";
import StoryModal from "components/StoryModal/StoryModal";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

import { getStories, createStory, updateStory, deleteStory } from "api/stories";

import { useAuth } from "context/AuthContext";
import { useUser } from "context/UserContext";

const Stories = () => {
	// hooks
	const { authData } = useAuth();
	const { canI } = useUser();
	const { t } = useTranslation();

	// table - states
	const [stories, setStories] = useState([]);
	const [story, setStory] = useState({});
	const [isDuplicate, setIsDuplicate] = useState(false);
	const [refreshTable, setRefreshTable] = useState(true);

	// modals - states
	const [showStoryModal, setShowStoryModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	// table - actions

	const rowActions = [];

	rowActions.push({
		name: "edit",
		icon: "ri-pencil-line",
		command: handleEditAction,
		title: t("Edit story"),
	});

	rowActions.push({
		name: "duplicate",
		icon: "ri-file-copy-line",
		command: handleDuplicateAction,
		title: t("Duplicate story"),
	});

	rowActions.push({
		name: "lock",
		icon: "ri-delete-bin-line",
		command: handleDeleteAction,
		title: t("Delete story"),
	});

	function handleEditAction(row) {
		setStory(row);
		setShowStoryModal(true);
	}

	function handleDuplicateAction(row) {
		setStory(row);
		setIsDuplicate(true);
		setShowStoryModal(true);
	}

	function handleDeleteAction(row) {
		setStory(row);
		setShowDeleteModal(true);
	}

	// create a story

	async function handleSubmit(data) {
		const props = {
			token: authData.access_token,
			title: data.title,
			description: data.description,
			isActive: data.isActive,
			isClassStudy: data.isClassStudy,
			isSelfStudy: data.isSelfStudy,
			isShared: data.isShared,
			patientId: data.patientId,
			categoryId: data.categoryId,
			difficultyId: data.difficultyId,
			courseId: data.courseId,
			sourceId: data.sourceId,
		};

		if (story && !isDuplicate) {
			const response = await updateStory({ storyId: story.id, ...props });
			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			} else {
				toast.success(t("Story updated!"));
				setRefreshTable(true);
				setShowStoryModal(false);
			}
		} else {
			const response = await createStory(props);
			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			} else {
				toast.success(t("Story created!"));
				setRefreshTable(true);
				setShowStoryModal(false);
			}
		}
	}

	// delete a story

	const handleDelete = async () => {
		try {
			const response = await deleteStory(authData.access_token, story.id);

			if (response.status === 204) {
				toast.success(t("Story deleted!"));
				setRefreshTable(true);
			} else {
				console.log(response);
			}
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setShowDeleteModal(false);
		}
	};

	// get the stories

	useEffect(() => {
		(async () => {
			const response = await getStories(authData.access_token);

			console.log("fetch response", response);

			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			} else {
				setStories(response.data);
				setRefreshTable(false);
			}
		})();
	}, [authData.access_token, refreshTable, t]);

	return (
		<>
			<div className="view">
				<div className="header">
					<h1>{t("Stories")}</h1>
					<HelpTooltip
						className="ml-2 text-gray-400"
						text={t(
							"List of all the stories. Teachers can add a new story and and use it in classes. Selected stories can also be made available for students doing home sessions. This view is only visible for teachers and admin.",
						)}
					/>
				</div>

				<div className="content">
					<StoriesTable
						data={stories}
						actions={rowActions}
						newAction={() => {
							setStory(null);
							setShowStoryModal(true);
						}}
						newActionLabel={t("Create new story")}
						onRowClick={handleEditAction}
					/>
				</div>
			</div>
			{showStoryModal && (
				<StoryModal
					storyData={story}
					isDuplicate={isDuplicate}
					onClose={() => {
						setStory(null);
						setIsDuplicate(false);
						setShowStoryModal(false);
					}}
					onSubmit={handleSubmit}
				/>
			)}
			{showDeleteModal && (
				<ConfirmationModal
					title={t("Delete story?")}
					message={`${t(
						"Are you sure you want to delete the story with the ID",
					)} ${story.id}? ${t("This operation cannot be undone.")}`}
					yesMessage={t("Delete")}
					onClose={() => setShowDeleteModal(false)}
					onSubmit={handleDelete}
				/>
			)}{" "}
		</>
	);
};

Stories.displayName = "Stories";

export default Stories;

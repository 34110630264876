import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAuth } from "context/AuthContext";
import { useUser } from "context/UserContext";
import { CanIView } from "context/UserContext";

import PrivateRoutes from "components/PrivateRoutes/PrivateRoutes";
import Login from "views/Login/Login";
import Logout from "views/Logout/Logout";
import Register from "views/Register/Register";
import ValidateCode from "views/Register/ValidateCode";
import Reset from "views/ResetPassword/Reset";
import ResetCode from "views/ResetPassword/ResetCode";
import ResetPassword from "views/ResetPassword/ResetPassword";
import Page404 from "views/404/Page404";

import LoginLayout from "layouts/LoginLayout/LoginLayout";
import MainLayout from "layouts/MainLayout/MainLayout";
import Dashboard from "views/Dashboard/Dashboard";
import Classes from "views/Classes/Classes";
import SingleClass from "views/SingleClass/SingleClass";
import Stories from "views/Stories/Stories";
import Patients from "views/Patients/Patients";
import Users from "views/Users/Users";
import Sessions from "views/Sessions/Sessions";
import SingleSession from "views/SingleSession/SingleSession";
import SingleTreatmentPlan from "views/SingleTreatmentPlan/SingleTreatmentPlan";
import Profile from "views/Profile/Profile";
import Settings from "views/Settings/Settings";
import TermsAndConditions from "views/TermsAndConditions/TermsAndConditions";

const App = () => {
  const { t } = useTranslation();
  const { authData, isLoading } = useAuth();
  const { canI } = useUser();

  const isToken = authData && authData.access_token ? true : false;
  const isValid = 1;
  let isAuthenticated = isToken && isValid;

  if (!isValid) {
    isAuthenticated = false;
  }

  if (isLoading)
    return (
      <div className="h-screen w-full flex center">
        <div>{t("Loading...")}</div>
      </div>
    );

  return (
    <>
      <Routes>
        {!isAuthenticated ? (
          <Route element={<LoginLayout />}>
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/validate-code" element={<ValidateCode />} />
            <Route path="/reset" element={<Reset />} />
            <Route path="/reset-code" element={<ResetCode />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/logout" element={<Logout />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
          </Route>
        ) : (
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        )}
        <Route element={<PrivateRoutes authData={authData} role="Student" />}>
          <Route element={<MainLayout />}>
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            <Route
              index
              path="/dashboard"
              element={<CanIView element={<Dashboard />} />}
            />
            <Route
              path="/classes"
              element={<CanIView element={<Classes />} />}
            />
            <Route
              path="/classes/:classId"
              element={<CanIView element={<SingleClass />} />}
            />
            <Route
              path="/stories"
              element={<CanIView element={<Stories />} />}
            />
            <Route
              path="/patients"
              element={<CanIView element={<Patients />} />}
            />
            <Route path="/users" element={<CanIView element={<Users />} />} />
            <Route
              path="/sessions"
              element={<CanIView element={<Sessions />} />}
            />
            <Route
              path="/session/:sessionId"
              element={
                <CanIView
                  element={
                    <SingleSession
                      readOnly={!canI("create", "SingleTreatmentPlan")}
                    />
                  }
                />
              }
            />
            <Route
              path="/session/:sessionId/treatment"
              element={<CanIView element={<SingleTreatmentPlan />} />}
            />
            <Route
              path="/session/:sessionId/treatment/:treatmentPlanId"
              element={
                <CanIView
                  element={
                    <SingleTreatmentPlan
                      readOnly={!canI("create", "Prescription")}
                    />
                  }
                />
              }
            />
            <Route path="/profile" element={<Profile />} />
            <Route
              path="/settings"
              element={<CanIView element={<Settings />} />}
            />
          </Route>
          <Route path="*" element={<Page404 />} />
        </Route>
        <Route path="*" element={<Page404 />} />
      </Routes>
    </>
  );
};

export default App;

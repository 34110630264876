import { useTranslation } from "react-i18next";

import Modal from "components/Modal/Modal";
import Button from "components/Form/Button/Button";

// import { AuthContext } from "./../../context/AuthContext";

const ConfirmationModal = ({
	title = "Confirmation",
	message,
	yesMessage = "OK",
	onSubmit,
	onClose,
	...props
}) => {
	const { t /*, i18n*/ } = useTranslation();

	return (
		<Modal title={title} isOpen={props.isOpen} onClose={onClose} {...props}>
			<form className="gap-4">
				<p className="my-4">{message}</p>
				<div className="flex center mt-8">
					<Button className="btn" onClick={onClose}>
						{t("Cancel")}
					</Button>
					<Button className="btn-primary" onClick={onSubmit}>
						{t(yesMessage)}
					</Button>
				</div>
			</form>
		</Modal>
	);
};

export default ConfirmationModal;

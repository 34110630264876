import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Form/Button/Button";
import Table from "components/Table/Table";
import Badge from "components/Badge/Badge";
import { getDate } from "utils/utils";

const Actions = ({ row, actions = [], readOnly = false }) => {
  const getClassByAction = (row, action, readOnly) => {
    const classList = ["btn-action"];

    switch (action.name) {
      case "readAll":
        break;
      case "read":
        if (row.status === "Draft") classList.push("disabled");
        break;
      case "edit":
        if (row.status !== "Draft") classList.push("disabled");
        break;
      case "review":
        if (row.status !== "Submitted") classList.push("disabled");
        break;
      case "delete":
        if (row.status !== "Draft") classList.push("disabled");
        break;
      default:
    }

    return classList.join(" ");
  };

  return (
    <div className="actions-wrapper">
      {actions.map(function (action, idx) {
        return (
          <Button
            key={idx}
            className={getClassByAction(row, action, readOnly)}
            onClick={() => action.command(row.id)}
            title={action.title}
          >
            <i className={action.icon}></i>
          </Button>
        );
      })}
    </div>
  );
};

const ClassSessionsTable = ({
  data,
  columns,
  actions,
  newAction,
  newActionLabel,
  readOnly = false,
  onRowClick,
}) => {
  console.log(columns);
  const { t /*, i18n*/ } = useTranslation();
  const _columns = useMemo(
    () =>
      columns || [
        {
          Header: t("Session ID"),
          accessor: "id",
          cssClass: "min-cell-width",
        },
        {
          Header: t("Author"),
          accessor: "creator",
          cssClass: "min-cell-width font-medium",
        },
        {
          Header: t("Status"),
          accessor: "status",
          cssClass: "text-sm min-cell-width text-xs",
          Cell: ({ cell: { value } }) => (
            <Badge className="badge-status" value={value} />
          ),
        },
        {
          Header: t("Date created"),
          accessor: "created_at",
          cssClass: "text-center min-cell-width",
          Cell: ({ cell: { value } }) => getDate(value),
        },
        {
          Header: t("Grade"),
          accessor: "grade",
          cssClass: "text-center text-xs min-cell-width",
          Cell: ({ cell: { value } }) =>
            value ? <Badge className="badge-status" value={value} /> : "-",
        },
        /*{
        Header: t("Reviewer"),
        accessor: "reviewer",
      },*/
        {
          Header: t("Date graded"),
          accessor: "reviewed_at",
          cssClass: "text-center min-cell-width",
          Cell: ({ cell: { value } }) => getDate(value),
        },
      ],
    [t],
  );

  if (actions.length)
    columns.push({
      Header: t("Actions"),
      accessor: "accessor",
      cssClass: "text-center min-cell-width pr-5",
      disableSortBy: true,
      Cell: ({ row: { original } }) => (
        <Actions row={original} actions={actions} readOnly={readOnly} />
      ),
    });

  return (
    <>
      <Table
        className="bg-white"
        columns={columns}
        data={data}
        searchLabel={t("Search by author")}
        searchColumns={["creator"]}
        newAction={readOnly === true ? "" : newAction}
        newActionLabel={newActionLabel}
        onRowClick={onRowClick}
      />
    </>
  );
};

export default ClassSessionsTable;

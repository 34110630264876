import { useState } from "react";
import { Tab } from "@headlessui/react";

function FilterTabs({ className, filters, onChange }) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleTabChange = (index) => {
    setSelectedIndex(index);
    onChange(index);
  };

  return (
    <Tab.Group selectedIndex={selectedIndex} onChange={handleTabChange}>
      <Tab.List className={`flex gap-2 ${className}`}>
        {filters.map((filter, index) => (
          <Tab
            key={index}
            className={({ selected }) =>
              `py-1 mr-2 -mb-[1px] mt-[1px] text-sm font-medium border-b-4 rounded-t
               focus-visible:outline outline-2 focus-visible:outline-primary outline-offset-1
               ${
                 selected
                   ? "border-b-primary text-primary"
                   : "border-b-transparent"
               }`
            }
          >
            {filter.label}
          </Tab>
        ))}
      </Tab.List>
    </Tab.Group>
  );
}

export default FilterTabs;

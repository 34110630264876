import { useState, useEffect } from "react";
import { Tab } from "@headlessui/react";
import { useTranslation } from "react-i18next";

import { InlineSpinner } from "components/Spinner/Spinner";
import TabHeader from "components/Tabs/TabHeader";
import StoryWidget from "components/StoryWidget/StoryWidget";
import PatientWidget from "components/PatientWidget/PatientWidget";
import PrescriptionsWidget from "components/PrescriptionsWidget/PrescriptionsWidget";

const CaseTabs = ({
  className = "",
  cases = [],
  selectedIndex,
  allowAdd = true,
  onAddTab = null,
  onRemoveTab = null,
  onChange = null,
  onShowReviewBox = null,
  onShowDeleteCaseModal = null,
  onOpenPatientModal = null,
  onOpenPrescriptionModal = null,
}) => {
  const { t } = useTranslation();
  const [isDraft, setIsDraft] = useState();

  useEffect(() => {
    // set draft to true if cases is empty or if the last case has the id 0
    setIsDraft(cases.length === 0 || cases[cases.length - 1]?.id === 0);
  }, [cases]);

  const isLoading = cases === null;

  const handleAddTab = () => {
    setIsDraft(true);
    onAddTab && onAddTab();
  };

  const handleRemoveTab = () => {
    setIsDraft(false);
    onRemoveTab && onRemoveTab();
  };

  return (
    <Tab.Group
      as="div"
      className="tabs-wrapper h-full flex flex-col"
      selectedIndex={selectedIndex}
      onChange={onChange}
    >
      <Tab.List className={`ml-2 flex gap-1 ${className}`}>
        {cases.map((caseItem, index) => (
          <Tab
            key={index}
            className={({ selected }) =>
              `py-1 px-2 -mb-[1px] mt-[1px] text-sm font-medium border border-gray-400 rounded-t
               focus-visible:outline outline-2 focus-visible:outline-primary outline-offset-1
               ${
                 selected
                   ? " border-b-4 border-b-primary text-primary bg-gray-100"
                   : " border-b-0 border-b-transparent text-gray-500"
               }`
            }
          >
            {caseItem.story_title ||
              `${caseItem.patient_first_name} ${caseItem.patient_last_name}`}
          </Tab>
        ))}

        {isLoading && (
          <Tab className="py-1 px-2 -mb-[1px] mt-[1px] text-sm font-medium border border-gray-400 rounded-t focus-visible:outline outline-2 focus-visible:outline-primary outline-offset-1 text-gray-500 border-b-4 border-b-transparent flex justify-center items-center">
            <span className="flex justify-center items-center">
              &nbsp;
              <InlineSpinner isLoading={true} />
              &nbsp;
            </span>
          </Tab>
        )}

        {!isLoading && cases.length === 0 && (
          <Tab className="py-1 px-2 -mb-[1px] mt-[1px] text-sm font-medium border border-gray-400 rounded-t focus-visible:outline outline-2 focus-visible:outline-primary outline-offset-1 text-gray-500 border-b-4 border-b-transparent flex justify-center items-center">
            <span className="flex justify-center items-center">
              {t("No cases started yet")}
            </span>
          </Tab>
        )}

        {allowAdd && !isDraft && cases.length > 0 && (
          <button
            className={`btn-primary rounded px-1 my-1 ${
              isDraft ? "disabled" : ""
            }`}
            title={t("Add new case")}
            onClick={handleAddTab}
            disabled={isDraft}
          >
            <i className="ri-add-line"></i>
          </button>
        )}

        {allowAdd && (isDraft || cases.length === 0) && (
          <button
            className="rounded px-1 my-1 "
            disabled={cases.length === 0}
            onClick={handleRemoveTab}
            title={
              cases.length === 1
                ? t("At least one tab is required")
                : t("Remove draft case")
            }
          >
            <i className="ri-close-line"></i>
          </button>
        )}
      </Tab.List>
      <Tab.Panels className="h-full border border-gray-400 rounded">
        {cases.map((caseItem, index) => (
          <Tab.Panel key={index} className="p-4 flex flex-col gap-6">
            <TabHeader
              id={caseItem.id}
              statusId={caseItem?.status_id}
              onShowReviewBox={onShowReviewBox}
              onShowDeleteCaseModal={() => onShowDeleteCaseModal(caseItem?.id)}
            />
            <div>
              <h3 className="mb-2">{t("Story")}</h3>
              <StoryWidget
                storyId={caseItem.story_id}
                story={caseItem.story_description || t("No story selected")}
              />
            </div>
            <div>
              <h3 className="mb-2">{t("Patient")}</h3>
              <PatientWidget
                patientId={caseItem.patient_id}
                onSubmit={onOpenPatientModal}
              />
            </div>
            <h3>{t("List of prescriptions")}</h3>
            <PrescriptionsWidget
              caseId={caseItem.id}
              onEdit={(id) => onOpenPrescriptionModal(id)}
              onSubmit={onOpenPrescriptionModal}
            />
          </Tab.Panel>
        ))}

        {cases.length === 0 && (
          <Tab.Panel className="p-4 flex flex-col gap-2">
            <TabHeader id={0} />
            <h3>{t("Story")}</h3>
            <StoryWidget />
            <h3>{t("Patient")}</h3>
            <PatientWidget patientId={0} onSubmit={onOpenPatientModal} />
            <h3>{t("List of prescriptions")}</h3>
            <PrescriptionsWidget
              caseId={0}
              readOnly={isDraft}
              onEdit={(id) => onOpenPrescriptionModal(id)}
              onSubmit={onOpenPrescriptionModal}
            />
          </Tab.Panel>
        )}
      </Tab.Panels>
    </Tab.Group>
  );
};

export default CaseTabs;

import React, { useEffect, useState } from "react";

import { getStory } from "api/stories";

import { useAuth } from "context/AuthContext";

const StoryWidget = ({ storyId }) => {
  const { authData } = useAuth();
  const [story, setStory] = useState(null);

  useEffect(() => {
    if (!storyId) return;

    const loadStory = async () => {
      try {
        const response = await getStory(authData.access_token, storyId);
        console.log(response);
        setStory(response);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    loadStory();
  }, [storyId, authData.access_token]);

  return (
    <div className="story p-4 bg-slate-100">
      {storyId && (
        <p className="max-w-3xl">{story?.description || "Loading.."}</p>
      )}
      {!storyId && <p className="max-w-3xl">No story selected</p>}
    </div>
  );
};

export default StoryWidget;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useAuth } from "context/AuthContext";
import { useUser } from "context/UserContext";
import { getAuthToken } from "api/auth";
import { getCurrentUser } from "api/users";

import Input from "./../../components/Form/Input/Input";
import Password from "./../../components/Form/Input/Password";
import Button from "./../../components/Form/Button/Button";

const isValidInput = () => {
  let isValid = true;

  // validation comes here

  return isValid;
};

/**
 * Login view
 */
const Login = () => {
  const { t /*, i18n*/ } = useTranslation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  // const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { setAuthData } = useAuth();
  const { setUserData } = useUser();

  const branch = process.env.REACT_APP_BRANCH;

  const handleSubmit = async (e) => {
    e.preventDefault();

    // validation
    if (!isValidInput()) {
    }

    // authentication
    try {
      const response = await getAuthToken({
        username,
        password,
      });
      if (response.code === "ERR_BAD_REQUEST") {
        toast.error(t(response.response.data.detail));
        if (response.response.data.detail === "User is not confirmed") {
          setUserData({ email: username });
          navigate("/validate-code");
        }
        throw new Error(response.response.data.detail);
      }

      const user = await getCurrentUser(response.access_token);

      setAuthData(response);
      setUserData(user);
      setUsername("");
      setPassword("");
      navigate("/dashboard");
    } catch (error) {
      setErrorMsg(t(String(error)));
    }
  };

  return (
    <>
      {branch && (
        <div className="relative">
          <div className="px-2 bg-red-500 text-white font-bold rounded left-1/2 -translate-x-1/2 -translate-y-3 absolute">
            {branch}
          </div>
        </div>
      )}
      <form
        className="card w-80 p-5 my-5 flex flex-col gap-5"
        onSubmit={handleSubmit}
      >
        <h2 className="mt-8 mb-4">{t("Log in")}</h2>
        <p className="message__error">{errorMsg}</p>
        <Input
          id="username"
          className="mt-4"
          label="E-mail"
          type="email"
          value={username}
          required
          onChange={(e) => setUsername(e.target.value)}
        />
        <Password
          id="password"
          className="mt-4"
          label={t("Password")}
          name="password"
          value={password}
          required
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="text-right">
          <Link to="/reset" className="text-sm">
            {t("Forgot password?")}
          </Link>
        </div>
        <Button className="btn-primary my-8">{t("Log in")}</Button>
      </form>
      <Link className="font-sm" to="/register">
        {t("I don't have an account yet")}
      </Link>
    </>
  );
};

export default Login;
